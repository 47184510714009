import { Injectable } from '@angular/core';
import {Query,Mutation, gql, Apollo} from 'apollo-angular';
import { Subject } from 'rxjs';

const queryRfq = gql`
query rfqList($first: Int, $after: String) {
  rfqList(input: {first: $first, after: $after}) {
        pageInfo{
            endCursor
            hasNextPage
        }
        edges{
          cursor
          haveActivePO
          isPoSent
          node{
              id
              companyLocation {
                  id
                  label
                  country
                  province
                  city
                  street
                  postalCode
                  phone
                  taxId
                  isMainAddress
              }
              state
              approvalStatus
              ongoingApprovalStatus
              rfqDisplayId
              description,
              submitQuotationLastDate
              paymentTermRequestedInDays
              etaRequested
              currency
              procurementRefNumber
              isForFullQuote
              isIndentAccepted
              expiredDate
              rfqItem {
                  id
                  name
                  brand
                  description
                  qtyNeeded
                  manufacturerSku
                  itemSequence
                  itemUnit
              }
              rfqVendorCategory {
                  id
                  category
              }
              totalVendorParticipation
              createdDate
              createdBy
              modifiedDate
              modifiedBy
          }
      }
  }
}
`;

const searchCategoryIndustry = gql`
  query loadSystemIndustryList($keyword:String!,$first: Int!, $after: String!){
      loadSystemIndustryList(input: {
          keyword:$keyword
          first: $first
          after: $after
      }){
          pageInfo{
              endCursor
              hasNextPage
          }
          edges{
              cursor
              node{
                  id
                  industry
                  description
              }
          }
      }
  }
`;

const queryFavouriteVendorList = gql`
query{
  viewFavouriteVendorList{
      vendorId
      vendorName
  }
}
`;

const inviteVendorToRfq = gql`
mutation inviteVendorToRfq($rfqId:ID!,$usingInviteVendorID:[String], $usingInviteEmail:[String]){
  inviteVendorToRfq(input: {
      rfqId: $rfqId
      inviteVendorToRfqInputData: {
          usingInviteVendorID: $usingInviteVendorID
          usingInviteEmail: $usingInviteEmail
      }
  })
}
`;


const setReccurringRfq = gql`
mutation addRfqToRecurringList($rfqId:ID!,$recurringName:String){
  addRfqToRecurringList(input: {
      rfqId: $rfqId
      recurringName: $recurringName
  }){
      id
      recurringName
      rfqId
  }
}
`;

const queryreccurringRfq = gql`
query viewRecurringRfqList($first: Int, $after: String) {
  viewRecurringRfqList(input: {first: $first, after: $after}) {
      pageInfo{
          endCursor
          hasNextPage
      }
      edges{
          cursor
          node{
              id
              rfqId
              recurringName
              rfqVendorCategory
              rfqCountry
              rfqProvince
          }
      }
  }
}

`;

const getRrfqDetail = gql`
query getRfqDetail($rfqId: ID!) {
  getRfqDetail(input: {
      rfqId: $rfqId
  }){
      id
      companyLocation {
          id
          label
          country
          province
          city
          street
          postalCode
          phone
          taxId
          isMainAddress
      }
      state
      approvalStatus
      ongoingApprovalStatus
      rfqDisplayId
      description,
      submitQuotationLastDate
      paymentTermRequestedInDays
      etaRequested
      currency
      procurementRefNumber
      isForFullQuote
      isIndentAccepted
      expiredDate
      rfqItem {
          id
          name
          brand
          description
          qtyNeeded
          manufacturerSku
          itemSequence
          itemUnit
          fileName
      }
      rfqVendorCategory {
          id
          category
      }
      totalVendorParticipation
      createdDate
      createdBy
      modifiedDate
      modifiedBy
  }
}
`;


const getPoList = gql`
query getPoList($rfqId: String!){
  getPoList(input: {
      rfqId: $rfqId
  }){
      poRfqData{
          rfqDisplayId
          description
          rfqVendorCategory
          submitQuotationLastDate
          rfqDeliveryCountry
          rfqDeliveryProvince
          rfqDeliveryCity
          rfqDeliveryStreet
          rfqDeliveryPostalCode
          paymentTermRequestedInDays
          etaRequested
          currency
          procurementRefNumber
      }
      totalActiveVendorChoose
      totalActivePrice
      poListData{
          poId
          batchNumber
          isSent
          isVoid
          approvedBy
          approvedDate
          poNumber
          poVendorData{
              vendorName
              isEtaMatch
              isPaymentTermMatch
              isDeliveryMatch
              totalPrice
          }
      }
  }
}
`;


const getRfqListHaveActivePO = gql`
query getRfqListHaveActivePO($first: Int, $after: String){
  getRfqListHaveActivePO(input: {
      first: $first
      after: $after
  }){
      pageInfo{
          endCursor
          hasNextPage
      }
      edges{
          cursor
          node{
              id
              companyLocation {
                  country
                  province
                  city
                  street
              }
              state
              rfqDisplayId
              submitQuotationLastDate
              etaRequested
              expiredDate
          }
          haveActivePO
      }
  }
}
`;

const getCompleteOrderQuotationRfq = gql`
query getCompleteOrderQuotation($rfqId: ID!){
  getCompleteOrderQuotation(input: {
    rfqId: $rfqId
  }){
    selectedOffer
    completeOrderQuotation{
        quotationId
        vendorName
        totalQuotationPrice
        isBlackList
        isEtaMatch
        isPaymentTermMatch
        isDeliverToMatch
        isSelected
        vendorId
    }
  } 
}`;


const getRfqItemListDataRfq = gql`
query getRfqItemListData($rfqId: ID!,$limit: Int! , $offset: Int!){
  getRfqItemListData(input: {
      rfqId: $rfqId
      limit: $limit
      offset: $offset
  }){
      totalItem
      quotedRfqItem {
          rfqItemId
          name
          brand
          description
          qtyNeeded
          manufacturerSku
          itemSequence
          itemUnit
          totalSelectedQty
          bestOffer
          totalSelectedOffer
          isQuotationHaveMoreItem
          vendorQuotationOfferData {
              quotationItemId
              quotationId
              vendorName
              qtyOffered
              isBlackList
              isProductManufactureCodeAndBrandMatch
              isEtaMatch
              isPaymentTermMatch
              isDeliverToMatch
              selectedQty
              offeredPrice
              totalOffered
              vendorId
          }
      } 
  }
}
`;


const getAllVendorQuotationOfferedDataRfq = gql`
query getAllVendorQuotationOfferedData($rfqId: ID!,$rfqItemId: ID!,$limit:Int!,$offset:Int!){
  getAllVendorQuotationOfferedData(input: {
      rfqId: $rfqId
      rfqItemId: $rfqItemId
      limit: $limit
      offset: $offset
  }){
    totalData
    vendorQuotationOfferData{
        quotationItemId
        quotationId
        vendorName
        qtyOffered
        isBlackList
        isProductManufactureCodeAndBrandMatch
        isEtaMatch
        isPaymentTermMatch
        isDeliverToMatch
        selectedQty
        offeredPrice
        totalOffered
        vendorId
    }
  } 
}
`;


const PostUpdateItemRfq = gql`
mutation updateRfqQuotationSelectedQty($rfqId: ID!,$updateRfqQuotationSelectedQtyInputData:[UpdateRfqQuotationSelectedQtyInputData]){
  updateRfqQuotationSelectedQty(input: {
      rfqId: $rfqId
      updateRfqQuotationSelectedQtyInputData: $updateRfqQuotationSelectedQtyInputData
  })
}
`;

const selectRfqQuotationForFullOrder = gql`
mutation selectRfqQuotationForFullOrder($rfqId: ID!,$selectRfqQuotationForFullOrderInputData:[SelectRfqQuotationForFullOrderInputData]!){
  selectRfqQuotationForFullOrder(input: {
      rfqId: $rfqId
      selectRfqQuotationForFullOrderInputData: $selectRfqQuotationForFullOrderInputData
  })
}
`;



const searchRfqHistory = gql`
query searchRfqHistory($rfqSearchInputData:RfqSearchInputData!, $first: Int, $after: String){
  searchRfqHistory(input: {
      rfqSearchInputData: $rfqSearchInputData
      first: $first
      after: $after
  }){
      pageInfo{
          endCursor
          hasNextPage
      }
      edges{
          cursor
          node{
              id
              companyLocation {
                  id
                  label
                  country
                  province
                  city
                  street
                  postalCode
                  phone
                  taxId
                  isMainAddress
              }
              state
              approvalStatus
              ongoingApprovalStatus
              rfqDisplayId
              description,
              submitQuotationLastDate
              paymentTermRequestedInDays
              etaRequested
              currency
              procurementRefNumber
              isForFullQuote
              isIndentAccepted
              expiredDate
              rfqItem {
                  id
                  name
                  brand
                  description
                  qtyNeeded
                  manufacturerSku
                  itemSequence
                  itemUnit
              }
              rfqVendorCategory {
                  id
                  category
              }
              totalVendorParticipation
              createdDate
              createdBy
              modifiedDate
              modifiedBy
          }
      }
  }
}
`;


const searchRfqList = gql`
query searchRfq($rfqSearchInputData:RfqSearchInputData!, $first: Int, $after: String){
  searchRfq(input: {
      rfqSearchInputData: $rfqSearchInputData
      first: $first
      after: $after
  }){
      pageInfo{
          endCursor
          hasNextPage
      }
      edges{
          cursor
          node{
              id
              companyLocation {
                  id
                  label
                  country
                  province
                  city
                  street
                  postalCode
                  phone
                  taxId
                  isMainAddress
              }
              state
              approvalStatus
              ongoingApprovalStatus
              rfqDisplayId
              description,
              submitQuotationLastDate
              paymentTermRequestedInDays
              etaRequested
              currency
              procurementRefNumber
              isForFullQuote
              isIndentAccepted
              expiredDate
              rfqItem {
                  id
                  name
                  brand
                  description
                  qtyNeeded
                  manufacturerSku
                  itemSequence
                  itemUnit
              }
              rfqVendorCategory {
                  id
                  category
              }
              totalVendorParticipation
              createdDate
              createdBy
              modifiedDate
              modifiedBy
          }
      }
  }
}
`;

const deleteRecurringRfq = gql`
mutation deleteRecurringRfq($id:String!){
  deleteRecurringRfq(input: $id)
}
`;


const RfqStepper = gql`
query getRfqStepper($rfqId:ID!){
  getRfqStepper(input: {
      rfqId: $rfqId
  }){
      isPublished
      totalQuotationReceive
      isAlreadyOrder
      isAlreadySentPo
  }
}
`;

const rfqHistoryList = gql`
query rfqHistoryList($first: Int, $after: String){
  rfqHistoryList(input: {first: $first, after: $after}){
      pageInfo{
          endCursor
          hasNextPage
      }
      edges{
          cursor
          node{
              id
              companyLocation {
                  id
                  label
                  country
                  province
                  city
                  street
                  postalCode
                  phone
                  taxId
                  isMainAddress
              }
              state
              approvalStatus
              ongoingApprovalStatus
              rfqDisplayId
              description,
              submitQuotationLastDate
              paymentTermRequestedInDays
              etaRequested
              currency
              procurementRefNumber
              isForFullQuote
              isIndentAccepted
              expiredDate
              rfqItem {
                  id
                  name
                  brand
                  description
                  qtyNeeded
                  manufacturerSku
                  itemSequence
                  itemUnit
              }
              rfqVendorCategory {
                  id
                  category
              }
              totalVendorParticipation
              createdDate
              createdBy
              modifiedDate
              modifiedBy
          }
      }
  }
}

`;


const changeRfqState = gql`
mutation changeRfqState($action: stateAction!,$rfqId: ID!) {
  changeRfqState(input: {
    rfqId: $rfqId
    action: $action
  })
}
`;

@Injectable({ providedIn: 'root' })

export class RfqProcurementService{

    rfqlistObs = new Subject();
    SearchCategoryObs = new Subject();
    VendorListObs = new Subject();
    inviteVendorObs = new Subject();
    setReccurringObs = new Subject();
    reccurringlistObs = new Subject();
    rfqDetailObs = new Subject();
    getPoListObs = new Subject();
    getRfqListHaveActivePOObs = new Subject();
    getCompleteOrderQuotationRfqObs = new Subject();
    getRfqItemListDataRfqObs  = new Subject();
    getAllVendorQuotationOfferedDataRfqObs = new Subject();
    PostUpdateItemRfqObs = new Subject();
    selectRfqQuotationForFullOrderObs = new Subject();
    searchRfqHistoryObs = new Subject();
    searchRfqListObs = new Subject();
    deleteRecurringRfqObs = new Subject();
    RfqStepperObs = new Subject();
    rfqHistoryListObs = new Subject();
    changeRfqStateObs = new Subject();

    constructor(private apollo: Apollo) {}

    rfqList(first:any, after:any) {
        this.apollo
        .mutate({
          mutation: queryRfq,
          variables: {first: first, after: after},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.rfqlistObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.rfqlistObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getrfqList(first:any, after:any) {
        this.rfqlistObs = new Subject();
        this.rfqList(first, after);
        return this.rfqlistObs.asObservable();
    }

    searchCategory(keyword:any, first:any, after:any) {
        this.apollo
        .mutate({
          mutation: searchCategoryIndustry,
          variables: {keyword: keyword, first: first, after: after},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.SearchCategoryObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.SearchCategoryObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getsearchCategory(keyword:any, first:any, after:any) {
        this.SearchCategoryObs = new Subject();
        this.searchCategory(keyword, first, after);
        return this.SearchCategoryObs.asObservable();
    }

    FavouriteVendorList() {
        this.apollo
        .mutate({
          mutation: queryFavouriteVendorList,
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.VendorListObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.VendorListObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getFavouriteVendorList() {
        this.VendorListObs = new Subject();
        this.FavouriteVendorList();
        return this.VendorListObs.asObservable();
    }


    inviteVendorToRfq(rfqId:any, usingInviteVendorID:any, usingInviteEmail:any) {
        this.apollo
        .mutate({
          mutation: inviteVendorToRfq,
          variables: {rfqId: rfqId, usingInviteVendorID: usingInviteVendorID, usingInviteEmail: usingInviteEmail},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.inviteVendorObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.inviteVendorObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    postinviteVendorToRfq(rfqId:any, usingInviteVendorID:any, usingInviteEmail:any) {
        this.inviteVendorObs = new Subject();
        this.inviteVendorToRfq(rfqId, usingInviteVendorID, usingInviteEmail);
        return this.inviteVendorObs.asObservable();
    }


    addRfqToRecurringList(rfqId:any, recurringName:any) {
        this.apollo
        .mutate({
          mutation: setReccurringRfq,
          variables: {rfqId: rfqId, recurringName: recurringName},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.setReccurringObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.setReccurringObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    postaddRfqToRecurringList(rfqId:any, recurringName:any) {
        this.setReccurringObs = new Subject();
        this.addRfqToRecurringList(rfqId, recurringName);
        return this.setReccurringObs.asObservable();
    }

    reccurringList(first:any, after:any) {
        this.apollo
        .mutate({
          mutation: queryreccurringRfq,
          variables: {first: first, after: after},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.reccurringlistObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.reccurringlistObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getreccurringList(first:any, after:any) {
        this.reccurringlistObs = new Subject();
        this.reccurringList(first, after);
        return this.reccurringlistObs.asObservable();
    }

    RrfqDetail(rfqId:any) {
        this.apollo
        .mutate({
          mutation: getRrfqDetail,
          variables: {rfqId: rfqId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.rfqDetailObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.rfqDetailObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getRrfqDetail(rfqId:any) {
        this.rfqDetailObs = new Subject();
        this.RrfqDetail(rfqId);
        return this.rfqDetailObs.asObservable();
    }


    PoList(rfqId:any) {
        this.apollo
        .mutate({
          mutation: getPoList,
          variables: {rfqId: rfqId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getPoListObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getPoListObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getPoList(rfqId:any) {
        this.getPoListObs = new Subject();
        this.PoList(rfqId);
        return this.getPoListObs.asObservable();
    }

    RfqListHaveActivePO(first:any, after:any) {
        this.apollo
        .mutate({
          mutation: getRfqListHaveActivePO,
          variables: {first: first, after: after},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getRfqListHaveActivePOObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getRfqListHaveActivePOObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getRfqListHaveActivePO(first:any, after:any) {
        this.getRfqListHaveActivePOObs = new Subject();
        this.RfqListHaveActivePO(first, after);
        return this.getRfqListHaveActivePOObs.asObservable();
    }

    CompleteOrderQuotationRfq(rfqId:any) {
        this.apollo
        .mutate({
          mutation: getCompleteOrderQuotationRfq,
          variables: {rfqId: rfqId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getCompleteOrderQuotationRfqObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getCompleteOrderQuotationRfqObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getCompleteOrderQuotationRfq(rfqId:any) {
        this.getCompleteOrderQuotationRfqObs = new Subject();
        this.CompleteOrderQuotationRfq(rfqId);
        return this.getCompleteOrderQuotationRfqObs.asObservable();
    }
    

    
    RfqItemListDataRfq(rfqId:any, limit:any, offset:any) {
        this.apollo
        .mutate({
          mutation: getRfqItemListDataRfq,
          variables: {rfqId: rfqId, limit: limit, offset: offset},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getRfqItemListDataRfqObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getRfqItemListDataRfqObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getRfqItemListDataRfq(rfqId:any, limit:any, offset:any) {
        this.getRfqItemListDataRfqObs = new Subject();
        this.RfqItemListDataRfq(rfqId, limit, offset);
        return this.getRfqItemListDataRfqObs.asObservable();
    }



    

    
    AllVendorQuotationOfferedDataRfq(rfqId:any, rfqItemId:any, limit:any, offset:any) {
        this.apollo
        .mutate({
          mutation: getAllVendorQuotationOfferedDataRfq,
          variables: {rfqId: rfqId, rfqItemId: rfqItemId, limit: limit, offset: offset},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getAllVendorQuotationOfferedDataRfqObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getAllVendorQuotationOfferedDataRfqObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getAllVendorQuotationOfferedDataRfq(rfqId:any,rfqItemId:any, limit:any, offset:any) {
        this.getAllVendorQuotationOfferedDataRfqObs = new Subject();
        this.AllVendorQuotationOfferedDataRfq(rfqId, rfqItemId, limit, offset);
        return this.getAllVendorQuotationOfferedDataRfqObs.asObservable();
    }

    

    UpdateItemRfq(rfqId:any, updateRfqQuotationSelectedQtyInputData:any) {
        this.apollo
        .mutate({
          mutation: PostUpdateItemRfq,
          variables: {rfqId: rfqId, updateRfqQuotationSelectedQtyInputData: updateRfqQuotationSelectedQtyInputData},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.PostUpdateItemRfqObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.PostUpdateItemRfqObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    PostUpdateItemRfq(rfqId:any, updateRfqQuotationSelectedQtyInputData:any) {
        this.PostUpdateItemRfqObs = new Subject();
        this.UpdateItemRfq(rfqId, updateRfqQuotationSelectedQtyInputData);
        return this.PostUpdateItemRfqObs.asObservable();
    }


    

    selectRfqQuotationForFullOrder(rfqId:any, selectRfqQuotationForFullOrderInputData:any) {
        this.apollo
        .mutate({
          mutation: selectRfqQuotationForFullOrder,
          variables: {rfqId: rfqId, selectRfqQuotationForFullOrderInputData: selectRfqQuotationForFullOrderInputData},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.selectRfqQuotationForFullOrderObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.selectRfqQuotationForFullOrderObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    PostselectRfqQuotationForFullOrder(rfqId:any, selectRfqQuotationForFullOrderInputData:any) {
        this.selectRfqQuotationForFullOrderObs = new Subject();
        this.selectRfqQuotationForFullOrder(rfqId, selectRfqQuotationForFullOrderInputData);
        return this.selectRfqQuotationForFullOrderObs.asObservable();
    }


    searchRfqHistory(rfqSearchInputData:any, first:any, after:any) {
        this.apollo
        .mutate({
          mutation: searchRfqHistory,
          variables: {rfqSearchInputData: rfqSearchInputData, first: first, after: after},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.searchRfqHistoryObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.searchRfqHistoryObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getsearchRfqHistory(rfqSearchInputData:any, first:any, after:any) {
        this.searchRfqHistoryObs = new Subject();
        this.searchRfqHistory(rfqSearchInputData, first, after);
        return this.searchRfqHistoryObs.asObservable();
    }

    searchRfq(rfqSearchInputData:any, first:any, after:any) {
      this.apollo
      .mutate({
        mutation: searchRfqList,
        variables: {rfqSearchInputData: rfqSearchInputData, first: first, after: after},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.searchRfqListObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.searchRfqListObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    getsearchRfq(rfqSearchInputData:any, first:any, after:any) {
        this.searchRfqListObs = new Subject();
        this.searchRfq(rfqSearchInputData, first, after);
        return this.searchRfqListObs.asObservable();
    }



    deleteRecurringRfq(id:string) {
      this.apollo
      .mutate({
        mutation: deleteRecurringRfq,
        variables: {id},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.deleteRecurringRfqObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.deleteRecurringRfqObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    getdeleteRecurringRfq(id:string) {
        this.deleteRecurringRfqObs = new Subject();
        this.deleteRecurringRfq(id);
        return this.deleteRecurringRfqObs.asObservable();
    }
    

    RfqStepper(rfqId:any) {
        this.apollo
        .mutate({
          mutation: RfqStepper,
          variables: {rfqId: rfqId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.RfqStepperObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.RfqStepperObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getRfqStepper(rfqId:any) {
        this.RfqStepperObs = new Subject();
        this.RfqStepper(rfqId);
        return this.RfqStepperObs.asObservable();
    }

        
    rfqHistoryList(first:any, after:any) {
        this.apollo
        .mutate({
          mutation: rfqHistoryList,
          variables: {first: first, after: after},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.rfqHistoryListObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.rfqHistoryListObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getrfqHistoryList(first:any, after:any) {
        this.rfqHistoryListObs = new Subject();
        this.rfqHistoryList(first, after);
        return this.rfqHistoryListObs.asObservable();
    }

    changeRfqState(action: string,rfqId: string) {
        this.apollo
        .mutate({
          mutation: changeRfqState,
          variables: {action: action, rfqId: rfqId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.changeRfqStateObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.changeRfqStateObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    postChangeRfqState(action: string,rfqId: string) {
        this.changeRfqStateObs = new Subject();
        this.changeRfqState(action, rfqId);
        return this.changeRfqStateObs.asObservable();
    }
}