import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import {Query,Mutation, gql, Apollo} from 'apollo-angular';
import { Observable, Subject, delay } from 'rxjs';

const QueryproductImagePreSignedUrl= gql` 
query productImagePreSignedUrl($fileName:[String]!,$productName:String!){
  productImagePreSignedUrl(input:{
      productName: $productName
      fileName: $fileName
  }
  )
}
`;

const QuerycompanyDocumentPresignedUrl= gql` 
query companyDocumentPresignedUrl($fileName:String!, $companyDocumentType:CompanyDocumentType!){
  companyDocumentPresignedUrl(input:{
      fileName: $fileName
      companyDocumentType: $companyDocumentType
    }
  )
}
`;


const insertCompanyDocument= gql` 
mutation insertCompanyDocument($documentType:CompanyDocumentType!,$uploadedFileName:String!){
  insertCompanyDocument(input: {
      companyDocumentInputData: {
          documentType: $documentType
          uploadedFileName: $uploadedFileName
      }
  })
  {
      id
      type
      imageUrl
      uploadedFileName
  }
}
`;

const deleteCompanyDocument= gql` 
mutation deleteCompanyDocument($companyDocumentId:ID!){
  deleteCompanyDocument(input: {
      companyDocumentId: $companyDocumentId
  })
}
`;


const generatePreSignedUrlRfqItem = gql`
query generatePreSignedUrlRfqItem($rfqId:String!,$generatePreSignedUrlRfqItemInputData:[GeneratePreSignedUrlRfqItemInputData]!){
  generatePreSignedUrlRfqItem(input:{
      rfqId: $rfqId
      generatePreSignedUrlRfqItemInputData: $generatePreSignedUrlRfqItemInputData
  }
  ){
      rfqItemId
      url
  }
}
`;

const generatePreSignedUrlQuotationItem = gql`
query generatePreSignedUrlQuotationItem($quotationId:String!,$generatePreSignedUrlQuotationItemInputData:[GeneratePreSignedUrlQuotationItemInputData]!){
  generatePreSignedUrlQuotationItem(input:{
      quotationId: $quotationId
      generatePreSignedUrlQuotationItemInputData: $generatePreSignedUrlQuotationItemInputData
  }
  ){
      quotationItemId
      url
  }
}
`;


@Injectable({ providedIn: 'root' })

export class UploadImagesService{
    productImagePreSignedUrlObs = new Subject();
    companyDocumentPreSignedUrlObs = new Subject();
    insertCompanyDocumentObs = new Subject();
    deleteCompanyDocumentObs = new Subject();
    generatePreSignedUrlRfqItemObs = new Subject();
    generatePreSignedUrlQuotationItemObs = new Subject();
    resizeImageObs = new Subject();

    constructor(
      private apollo: Apollo,
      private http: HttpClient
    ) {}

    productImagePreSignedUrl(fileName:string[],productName:string) {
        this.apollo
        .mutate({
          mutation: QueryproductImagePreSignedUrl,
          variables: {fileName:fileName,productName:productName},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.productImagePreSignedUrlObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.productImagePreSignedUrlObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getproductImagePreSignedUrl(fileName:string[],productName:string) {
        this.productImagePreSignedUrlObs = new Subject();
        this.productImagePreSignedUrl(fileName,productName);
        return this.productImagePreSignedUrlObs.asObservable();
    }

    companyDocumentPreSignedUrl(fileName:string,companyDocumentType:string) {
        this.apollo
        .mutate({
          mutation: QuerycompanyDocumentPresignedUrl,
          variables: {fileName:fileName,companyDocumentType:companyDocumentType},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.companyDocumentPreSignedUrlObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.companyDocumentPreSignedUrlObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getcompanyDocumentPreSignedUrl(fileName:string,companyDocumentType:string) {
        this.companyDocumentPreSignedUrlObs = new Subject();
        this.companyDocumentPreSignedUrl(fileName,companyDocumentType);
        return this.companyDocumentPreSignedUrlObs.asObservable();
    }

    insertCompanyDocument(documentType:string,uploadedFileName:string) {
        this.apollo
        .mutate({
          mutation: insertCompanyDocument,
          variables: {documentType:documentType,uploadedFileName:uploadedFileName},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.insertCompanyDocumentObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.insertCompanyDocumentObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getinsertCompanyDocument(documentType:string,uploadedFileName:string) {
        this.insertCompanyDocumentObs = new Subject();
        this.insertCompanyDocument(documentType,uploadedFileName);
        return this.insertCompanyDocumentObs.asObservable();
    }
    
    deleteCompanyDocument(companyDocumentId:string) {
        this.apollo
        .mutate({
          mutation: deleteCompanyDocument,
          variables: {companyDocumentId:companyDocumentId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.deleteCompanyDocumentObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.deleteCompanyDocumentObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getdeleteCompanyDocument(companyDocumentId:string) {
        this.deleteCompanyDocumentObs = new Subject();
        this.deleteCompanyDocument(companyDocumentId);
        return this.deleteCompanyDocumentObs.asObservable();
    }


    generatePreSignedUrlRfqItem(rfqId:string, generatePreSignedUrlRfqItemInputData:any) {
        this.apollo
        .mutate({
          mutation: generatePreSignedUrlRfqItem,
          variables: {rfqId: rfqId, generatePreSignedUrlRfqItemInputData: generatePreSignedUrlRfqItemInputData},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.generatePreSignedUrlRfqItemObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.generatePreSignedUrlRfqItemObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }
    
    getgeneratePreSignedUrlRfqItem(rfqId:string, generatePreSignedUrlRfqItemInputData:any) {
        this.generatePreSignedUrlRfqItemObs = new Subject();
        this.generatePreSignedUrlRfqItem(rfqId, generatePreSignedUrlRfqItemInputData);
        return this.generatePreSignedUrlRfqItemObs.asObservable();
    }
    

    generatePreSignedUrlQuotationItem(quotationId:string, generatePreSignedUrlQuotationItemInputData:any) {
        this.apollo
        .mutate({
          mutation: generatePreSignedUrlQuotationItem,
          variables: {quotationId: quotationId, generatePreSignedUrlQuotationItemInputData: generatePreSignedUrlQuotationItemInputData},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.generatePreSignedUrlQuotationItemObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.generatePreSignedUrlQuotationItemObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }
    
    getgeneratePreSignedUrlQuotationItem(quotationId:string, generatePreSignedUrlQuotationItemInputData:any) {
        this.generatePreSignedUrlQuotationItemObs = new Subject();
        this.generatePreSignedUrlQuotationItem(quotationId, generatePreSignedUrlQuotationItemInputData);
        return this.generatePreSignedUrlQuotationItemObs.asObservable();
    }
    

    uploadAwsS3(url:string,file:any,time:number) {

      var rejectUnauthorizedOption = 'true';
      // if (isDevMode()) {
      //   rejectUnauthorizedOption = 'false';
      // }
      if (location.protocol !== 'https:') {
        rejectUnauthorizedOption = 'false';
      }

      const headers = { 
        'rejectUnauthorized': rejectUnauthorizedOption,
        // 'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
      };

      return this.http.put(url, file,{ headers }).pipe(delay(time))

    }

    resizeImage(image: File,fileName:string,typeValue:string) {
      const formData = new FormData();
      formData.append('image', image);
      //return this.http.post<any>(`imgs/upload/resize`, formData);


      this.http.post('imgs/upload/resize',formData, { responseType: 'blob' })
      .subscribe(blob => {
        const file = new File([blob], fileName, { type: typeValue });

        // Do something with the file, e.g., upload it or display it in an image tag
        console.log(file);
        this.resizeImageObs.next(file);
      }, error => {
        console.error('Error capturing image', error);
      }
    )

    }

    getresizeImage(image: File,fileName:string,type:string) {
        this.resizeImageObs = new Subject();
        this.resizeImage(image,fileName,type);
        return this.resizeImageObs.asObservable();
    }


    
}