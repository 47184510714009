<header id="web-header">
	<div class="main-container">
		<div class="header-left">
			<a href="{{redirectDashboardUrl()}}">
				<img src="/assets/images/logofh-cepetdapet.png" srcset="/assets/images/logofh-cepetdapet.png 1x, /assets/images/logofh-cepetdapet.png 2x" sizes="(max-width: 800px) 230px" alt="Cepetdapet" loading="lazy" width="230" height="60" class="logo">
			</a>
		</div><!-- .header-left -->
		<div class="header-right">

			<div class="child" *ngIf="!isMobile">
				<a href="/">
					<span class="content">
						<span class="text menu-text">{{'menu.home' | translate}}</span>
					</span>
				</a>
			</div>
			<div class="child" *ngIf="!isMobile">
				<a class="menu-select pointer" (click)="openSubMenu()">
					<span class="content">
						<span class="text menu-text">{{'menu.about' | translate}}</span>
					</span>
				</a>
				<ng-container *ngIf="showSubmenu">
					<div class="divSubmenu">
						<a id="mtmManfaatVendor" href="#manfaat-vendor" (click)="openSubMenu()">
							<div class="submenu">
								Vendor
							</div>
						</a>
						<a id="mtmManfaatProcurement" href="#manfaat-procurement" (click)="openSubMenu()">
							<div class="submenu">
								Procurement
							</div>
						</a>

					</div>
				</ng-container>
			</div>
			<div class="child" *ngIf="!isMobile">
				<a href="/subscription">
					<span class="content">
						<span class="text menu-text">{{'menu.subscription' | translate}}</span>
					</span>
				</a>
			</div>
			<div class="child" *ngIf="!isMobile">
				<a href="/help">
					<span class="content">
						<span class="text menu-text">{{'menu.help' | translate}}</span>
					</span>
				</a>
			</div>

			<div class="child d-none">
				<a href="" class="lang-select">
					<span class="content">
						<span class="text text-uppercase">{{currentlang}}</span>
					</span><!-- .content -->
				</a>
			</div><!-- .child -->
			<div class="child group-buttons">



					<!-- <a href="/login" *ngIf="!isLoggedin" class="btn btn-cta2">{{'menu.login' | translate}}</a>
					<a href="/regis" *ngIf="!isLoggedin" class="btn btn-cta btn-ghost">{{'menu.regis' | translate}}</a> -->
					<div class="n-1-hide n-768-show ">
						<!-- <a href="/" class="btn py-2 ">Beranda</a>
						<a  class="btn py-2 ">Tentang</a>
						<a href="/subscription" class="btn py-2 ">Beranggalan</a>
						<a href="/help" class="btn py-2 ">Bantuan</a> -->





						

						<a href="/login" *ngIf="!isLoggedin" class="btn btn-cta btn-ghost py-2">Login</a>
						<a href="/logout" *ngIf="isLoggedin" class="btn btn-cta btn-ghost py-2">Login</a>
						<a href="/regis" class="btn py-2 btn-cta">Register</a>
		
					</div>



					<ng-container *ngIf="isLoggedin" class="d-none">
						<!-- <font class="fhc2 pointer" [hidden]="getcompanyName==''">
						<a class="" href="{{redirectDashboardUrl()}}"><h5 class="my-1 cd2"><b><i class="fas fa-regular fa-user me-2"></i> {{limitText(userdata.fullname)}}</b></h5></a>
						</font> -->

						<!-- <a href="/logout" *ngIf="isLoggedin" class="btn btn-cta btn-ghost py-2">Login</a>
						<a href="/regis" *ngIf="isLoggedin" class="btn py-2 btn-cta">Register</a> -->

					</ng-container>
			</div><!-- .child -->
			<div class="child hamburger-container" *ngIf="isMobile">
				<button class="hamburger hamburger--spin" type="button" aria-label="Menu button">
					<span class="hamburger-box">
						<span class="hamburger-inner"></span>
					</span>
				</button>
			</div><!-- .child -->
		</div><!-- .header-right -->
	</div><!-- .main-container -->
</header>

<div id="mobile-menu-container">
	<div class="main-container">
		<div class="mobile-menu-content">
			<nav class="mobile-nav">
				<ul>
					<li class="nav-home">
						<a href="/">
							<span class="text">{{'menu.home' | translate}}</span>
						</a>
					</li>
					<!-- <li class="nav-about">
						<a href="">
							<span class="text">About Us</span>
						</a>
					</li> -->
					<li class="nav-product has-sub" *ngIf="isMobile">
						<a href="">
							<span class="text">{{'menu.about' | translate}}</span>
						</a>
						<div class="sub-nav">
							<ul>
								<li>
									<a href="#manfaat-vendor" id="mtmManfaatVendor">
										<span class="text">Vendor</span>
									</a>
								</li>
								<li>
									<a href="#manfaat-procurement" id="mtmManfaatProcurement">
										<span class="text">Procurement</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
					<!-- <li class="nav-news has-sub">
						<a href="">
							<span class="text">News &amp; Articles</span>
						</a>
						<div class="sub-nav">
							<ul>
								<li>
									<a href="">
										<span class="text">News</span>
									</a>
								</li>
								<li>
									<a href="">
										<span class="text">Articles</span>
									</a>
								</li>
								<li>
									<a href="">
										<span class="text">Promo</span>
									</a>
								</li>
								<li>
									<a href="">
										<span class="text">View All</span>
									</a>
								</li>
							</ul>
						</div>
					</li> -->



					<li class="nav-contact">
						<a href="/subscription">
							<span class="text">{{'menu.subscription' | translate}}</span>
						</a>
					</li>
					<li class="nav-contact">
						<a href="/help">
							<span class="text">{{'menu.help' | translate}}</span>
						</a>
					</li>
					<!-- 
					<li class="nav-contact">
						<a href="">
							<span class="text">Contact Us</span>
						</a>
					</li>
					<li class="nav-contact">
						<a href="#section-faq">
							<span class="text">FAQ</span>
						</a>
					</li> -->
					<li class="nav-contact buttons n-1-show n-768-hide ">
						<div class="d-none">
							<a href="/regis" class="btn py-2 btn-cta mt-3 btnpillMenu text-white">Register</a>

							<a href="/login" *ngIf="!isLoggedin" class="btn btn-cta btn-ghost  py-2 btnpillMenu mt-3">Login</a>
							<a href="/logout" *ngIf="isLoggedin" class="btn btn-cta btn-ghost  py-2 btnpillMenu mt-3">Login</a>
	
						</div>



	
	
						<!-- <ng-container *ngIf="isLoggedin">
							<font class="fhc2 pointer" [hidden]="getcompanyName==''">
							<a href="{{redirectDashboardUrl()}}"><h2 class="mt-2 mb-2 pt-2 ms-3"><b>{{limitText(userdata.fullname)}}</b></h2></a>
							</font>
						</ng-container> -->

					</li>
					<li class="nav-contact buttons">
						<ng-container *ngIf="isLoggedin">
								<!-- <a href="/logout" class="btn btn-cta btn-ghost mobile-btn">Logout</a> -->

								<!-- <a href="/logout" class="btn py-2 btn-cta text-white btnpillMenu mt-2">Logout</a> -->

						</ng-container>

			

					</li>
				</ul>
			</nav><!-- .mobile-nav -->
		</div><!-- .mobile-menu-content -->
	</div><!-- .main-container -->
	<a href="" class="close-mobile-nav">Close Menu</a>
</div><!-- #mobile-menu-container -->

<div id="search-box" class="floating-box-container">
	<div class="content">
		<div class="box-area">
			<h2 class="ngc-maintitle">What do you want to search?</h2>
			<form class="search-form">
				<input type="text" placeholder="Type here..." id="search-input-text" class="input-text"  [(ngModel)]="valSearch" (keydown.enter)="globalSearch()" [ngModelOptions]="{standalone: true}" required>
				<button class="submit-btn"  (click)="globalSearch()"><span class="fas fa-search icon"></span></button>
			</form><!-- .search-form -->
			<small  *ngIf="invalidSearch"><i class="text-danger">Please type keyword minimum 3 character.</i></small>
		</div><!-- .box-area -->
	</div><!-- .content -->
	<a href="" class="close-search">Close Search</a>
</div><!-- .floating-box-container -->

<div id="lang-box" class="floating-box-container">
	<div class="content">
		<div class="box-area">
			<h2 class="ngc-maintitle">Change Language</h2>
			<div class="language-list">
				<div class="child">
					<a (click)="switchLang('en')" class="{{currentlang=='en'?'selected':''}} clickLanguage">
						<span class="span-wrapper">
							<span class="icon-wrapper">
								<img src="/assets/homepage/images/flag-en-44px.gif" alt="ENGLISH" class="flag-icon" width="22" height="17">
							</span><!-- .icon-wrapper -->
							<span class="text-wrapper">
								English
								<span *ngIf="currentlang=='en'" class="far fa-check icon"></span>
							</span><!-- .text-wrapper -->
						</span><!-- .span-wrapper -->
					</a>
				</div><!-- .child -->
				<div class="child">
					<a (click)="switchLang('id')" class="{{currentlang=='id'?'selected':''}} clickLanguage">
						<span class="span-wrapper">
							<span class="icon-wrapper">
								<img src="/assets/homepage/images/flag-id-44px.gif" alt="BAHASA INDONESIA" class="flag-icon" width="22" height="17">
							</span><!-- .icon-wrapper -->
							<span class="text-wrapper">
								Bahasa Indonesia
								<span *ngIf="currentlang=='id'" class="far fa-check icon"></span>
							</span><!-- .text-wrapper -->
						</span><!-- .span-wrapper -->
					</a>
				</div><!-- .child -->
			</div><!-- .language-list -->
		</div><!-- .box-area -->
	</div><!-- .content -->
	<a href="" class="close-lang">Close Language Selection</a>
</div><!-- .floating-box-container -->

<div *ngIf="templateId=='0'">
	<section class="section has-padding mt-2 mt-sm-3 pb-0 bgceda">
		<div class="position-relative">
			<div class="position-absolute top-50 start-0 d-none d-lg-block" id="boxSearch">
				<div class="container mx-5" id="divboxSearch">
					<div id="borderSearch"></div>
					<div class="text-center  p-3">
						<h2 class="cd1 pt-3 mb-0">Ingin kembangkan bisnis?</h2>
						<p class="cd1">Temukan produk dan peluang bisnis lainnya bersama kami</p>
						<div class="px-2 mb-3">
							<div class="input-group" style="    border: 1px solid #cacaca;
    border-radius: 4px;">
								<span class="input-group-text border-0 px-2 iconSearch" id="basic-addon1"><i class="bi bi-search" aria-hidden="true"></i></span>
								<input type="text" class="form-control border-0 rounded-end ps-1" placeholder="Cari apa yang Anda butuhkan" [(ngModel)]="valSearchHero" [ngModelOptions]="{standalone: true}" [disabled]="loadingSearchHero">
							</div>
							<small class="fst-italic" *ngIf="invalidSearchHero"><i class="text-danger">Please type keyword minimum 3 character.</i></small>


						</div>
						<div class="px-2">
							<div class="btn-group w-100" role="group">
								<button type="button" class="btn btn-danger btnSearchL py-2" (click)="globalSearchProduct()">Search Product</button>
								<button type="button" class="btn btn-warning btnSearchR py-2" (click)="globalSearchRFQ()">Search RFQ</button>
							</div>
						</div>


					</div>
				</div>
			</div>

			<div class="d-none d-md-block">
				<ngb-carousel  #carouselTemp2 [interval]="6000" [showNavigationArrows]="true">
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
						<img src="/assets/images/homepage/slider3/Desktop/Homepage-Banner1.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/slider3/Desktop/Homepage-Banner2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/slider3/Desktop/Homepage-Banner3.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/slider3/Desktop/Homepage-Banner4.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
				</ngb-carousel>
			</div>
			<div class="d-block d-md-none">
				<ngb-carousel  #carouselTemp2M [interval]="6000" [showNavigationArrows]="true">
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
						<img src="/assets/images/homepage/slider3/Mobile/(M)Homepage-Banner1.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/slider3/Mobile/(M)Homepage-Banner2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/slider3/Mobile/(M)Homepage-Banner3.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/slider3/Mobile/(M)Homepage-Banner4.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
				</ngb-carousel>
			</div>



		</div>
		
		<div class="d-block d-lg-none p-2 p-sm-3">
			<div class="container" id="divboxSearchMobile">
				<div id="borderSearch"></div>
				<div class="text-center  p-3">
					<h2 class="cd1 pt-3 mb-0">Ingin kembangkan bisnis?</h2>
					<p class="cd1">Temukan produk dan peluang bisnis lainnya bersama kami</p>
					<div class="px-2 mb-3">
						<div class="input-group" style="    border: 1px solid #cacaca;
    border-radius: 4px;">
							<span class="input-group-text border-0 px-2 iconSearch" id="basic-addon1"><i class="bi bi-search" aria-hidden="true"></i></span>
							<input type="text" class="form-control border-0 rounded-end ps-1" placeholder="Cari apa yang Anda butuhkan" [(ngModel)]="valSearchHero" [ngModelOptions]="{standalone: true}" [disabled]="loadingSearchHero">
						</div>
						<small class="fst-italic" *ngIf="invalidSearchHero"><i class="text-danger">Please type keyword minimum 3 character.</i></small>
					</div>
					<div class="px-2">
						<div class="btn-group w-100" role="group">
							<button type="button" class="btn btn-danger btnSearchL py-2" (click)="globalSearchProduct()">Search Product</button>
							<button type="button" class="btn btn-warning btnSearchR py-2" (click)="globalSearchRFQ()">Search RFQ</button>
						</div>
					</div>


				</div>
			</div>
		</div>

	</section>

	<section class="section py-0 bgTemplate" data-aos="fade-in">
		<div class="main-container px-0">
			<div class="row">
				<div class="col-12 col-lg-4" >
					<div class="bannerCarousel">
				
						<div class="row g-0 align-items-center divTemplateLeft">
							<div class="col-12">
								<ngb-carousel  #carouselTemp2M [interval]="6000" [showNavigationArrows]="true">


									<ng-template ngbSlide *ngFor="let product of publicProducts?.publicProductData; let i = index">
		
										<div class="p-0 m-0 align-items-center">
											<div class="row p-0 m-0 g-0 pt-2 align-items-center">
		
												<div class="col-5 col-md-4 col-lg-6">
													<div class="py-0 py-sm-3">
														<ng-container *ngFor="let img of product?.imagesUrl; let x = index">
															<div *ngIf="x==0"  class="mx-auto banner-image-container">
																<img src="{{ img }}" alt="Image">
															</div>
														</ng-container>
													</div>

												</div>
												<div class="col-7 col-md-8 col-lg-6">
													<div class="">
														<h5 class="mt-2 mb-0 cd1">{{ limitTextSlides(product?.productName) }}</h5>
														<div class="py-3">
															<a href="/product/{{(product.companyDomain)?product.companyDomain:'view/'+product.vendorId}}/{{product.productSlugUrl}}" class="btn-ceda-third">Lihat Detail Produk</a>
														</div>
													</div>

												</div>
												<div class="col-12 bgcd2 vendorBrd text-center">
													<div class="py-2">
														<div class="row g-0 justify-content-center p-0 m-0">
															<div class="col-auto"><img src="/assets/images/icons/ic-building-store-24@3x.png" style="max-width: 24px;"  *ngIf="product?.vendorName"></div>
															<div class="col-auto">
																<font class="ps-2 textBannerCompany" *ngIf="product?.vendorName">{{ product?.vendorName }}</font>	
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
					
					
									</ng-template>
		
								</ngb-carousel>
							</div>
						</div>



					</div>

				</div>
				<div class="col-12 col-lg-8">
					<div class="row align-items-center divTemplate">
						<div class="col-5">
							<h5 class="cd1">RFQ Terbaru</h5>
						</div>
						<div class="col-7 text-end">
							<a class="pointer btn-ceda-third" href="/regis">Tambahkan RFQ anda</a>
						</div>
						<div class="col-12">
							<hr>
						</div>
						<div class="col-12" *ngIf="publicRFQLoading">

							<div class="text-center pt-5 pb-5">
								<div class="loader loader--style8" title="7">
								  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
									<rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
									</rect>
									<rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									</rect>
									<rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									</rect>
								  </svg>
								</div>
							</div>  

						</div>

						<div class="col-12" *ngIf="!publicRFQLoading">
							<div class="py-2 scrollspy-rfq">
								<ng-container *ngFor="let rfqboard of dataRfqBoard; let i = index">
									<div>
										<app-board-rfq [rfq]="rfqboard" [forceMobile]="true"></app-board-rfq>
									</div>
								</ng-container>


								<!-- <ng-container *ngFor="let rfqboard of dataRfqBoard; let i = index">
									<app-board [rfq]="rfqboard" [forceMobile]="true"></app-board>
								</ng-container> -->
							</div>


						</div>
						
					</div>
				</div>
			</div>
		</div>
	</section>


</div>

<ng-container *ngIf="templateId=='1'">
	<section class="section has-padding mt-2 mt-sm-3 pb-0">
		<div (touchstart)="posIni = $event.changedTouches[0].pageX"
		(touchend)="move($event.changedTouches[0].pageX)">
			<ngb-carousel  #carousel [interval]="6000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus">
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner1.1.jpg" class="d-none d-sm-block w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner1.jpg" class="d-block d-sm-none w-100 h-auto">
					</div>
				</ng-template>
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner2.1.jpg" class="d-none d-sm-block  w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner2.jpg" class="d-block d-sm-none  w-100 h-auto">
					</div>
				</ng-template>
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner3.1.jpg" class="d-none d-sm-block  w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner3.jpg" class="d-block d-sm-none  w-100 h-auto">
					</div>
				</ng-template>
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner4.1.jpg" class="d-none d-sm-block  w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner4.jpg" class="d-block d-sm-none  w-100 h-auto">
					</div>
				</ng-template>
			</ngb-carousel>		
		</div>
	</section>
</ng-container>
<div *ngIf="templateId=='2'">
	<section class="section has-padding mt-2 mt-sm-3 pb-0">
		<div class="position-relative">
			<div class="position-absolute top-50 start-0 d-none d-md-block" id="boxSearch">
				<div class="container mx-5" id="divboxSearch">
					<div id="borderSearch"></div>
					<div class="text-center  p-3">
						<h2 class="text-white pt-3 mb-0">Ingin kembangkan bisnis?</h2>
						<p class="text-white">Temukan produk dan peluang bisnis lainnya bersama kami</p>
						<div class="px-2 mb-3">
							<div class="input-group">
								<span class="input-group-text border-0 px-2 iconSearch" id="basic-addon1"><i class="bi bi-search" aria-hidden="true"></i></span>
								<input type="text" class="form-control border-0 rounded-end ps-1" placeholder="Cari apa yang Anda butuhkan" [(ngModel)]="valSearchHero" [ngModelOptions]="{standalone: true}" [disabled]="loadingSearchHero">
							</div>
							<small class="fst-italic" *ngIf="invalidSearchHero"><i class="text-danger">Please type keyword minimum 3 character.</i></small>


						</div>
						<div class="px-2">
							<div class="btn-group w-100" role="group">
								<button type="button" class="btn btn-danger btnSearchL py-2" (click)="globalSearchProduct()">Search Product</button>
								<button type="button" class="btn btn-warning btnSearchR py-2" (click)="globalSearchRFQ()">Search RFQ</button>
							</div>
						</div>


					</div>
				</div>
			</div>

			<div class="d-none d-sm-block">
				<ngb-carousel  #carouselTemp2 [interval]="6000" [showNavigationArrows]="true">
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
						<img src="/assets/images/homepage/Homepage_Banner1.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/Homepage_Banner2.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/Homepage_Banner3.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/Homepage_Banner4.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
				</ngb-carousel>
			</div>
			<div class="d-block d-sm-none">
				<ngb-carousel  #carouselTemp2M [interval]="6000" [showNavigationArrows]="true">
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
						<img src="/assets/images/homepage/(M)Homepage_Banner1.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/(M)Homepage_Banner2.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/(M)Homepage_Banner3.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/(M)Homepage_Banner4.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
				</ngb-carousel>
			</div>



		</div>
		
		<div class="d-block d-md-none p-2 p-sm-3">
			<div class="container" id="divboxSearchMobile">
				<div id="borderSearch"></div>
				<div class="text-center  p-3">
					<h2 class="text-white pt-3 mb-0">Ingin kembangkan bisnis?</h2>
					<p class="text-white">Temukan produk dan peluang bisnis lainnya bersama kami</p>
					<div class="px-2 mb-3">
						<div class="input-group">
							<span class="input-group-text border-0 px-2 iconSearch" id="basic-addon1"><i class="bi bi-search" aria-hidden="true"></i></span>
							<input type="text" class="form-control border-0 rounded-end ps-1" placeholder="Cari apa yang Anda butuhkan" [(ngModel)]="valSearchHero" [ngModelOptions]="{standalone: true}" [disabled]="loadingSearchHero">
						</div>
						<small class="fst-italic" *ngIf="invalidSearchHero"><i class="text-danger">Please type keyword minimum 3 character.</i></small>
					</div>
					<div class="px-2">
						<div class="btn-group w-100" role="group">
							<button type="button" class="btn btn-danger btnSearchL py-2" (click)="globalSearchProduct()">Search Product</button>
							<button type="button" class="btn btn-warning btnSearchR py-2" (click)="globalSearchRFQ()">Search RFQ</button>
						</div>
					</div>


				</div>
			</div>
		</div>

	</section>
</div>
<ng-container *ngIf="templateId=='3'">
	<section class="section has-padding mt-2 mt-sm-3 pb-0">
		<div (touchstart)="posIni = $event.changedTouches[0].pageX"
		(touchend)="move($event.changedTouches[0].pageX)">
			<ngb-carousel  #carousel [interval]="6000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus">
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner1.1.jpg" class="d-none d-sm-block w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner1.jpg" class="d-block d-sm-none w-100 h-auto">
					</div>
				</ng-template>
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner2.1.jpg" class="d-none d-sm-block  w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner2.jpg" class="d-block d-sm-none  w-100 h-auto">
					</div>
				</ng-template>
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner3.1.jpg" class="d-none d-sm-block  w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner3.jpg" class="d-block d-sm-none  w-100 h-auto">
					</div>
				</ng-template>
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner4.1.jpg" class="d-none d-sm-block  w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner4.jpg" class="d-block d-sm-none  w-100 h-auto">
					</div>
				</ng-template>
			</ngb-carousel>		
		</div>


	</section>
	<section class="section py-0 bgTemplate">
		<div class="main-container px-0">
			<div class="row">
				<div class="col-lg-7">
					<div class="row align-items-center divTemplate">
						<div class="col-5">
							<h5>Produk Pilihan</h5>
						</div>
						<div class="col-7 text-end">
							<a class="pointer" href="/regis">Tambahkan produk anda</a>
						</div>
						<div class="col-12">
							<hr>
						</div>
						<div class="col-12" *ngIf="publicProductsLoading">

							<div class="text-center pt-5 pb-5">
								<div class="loader loader--style8" title="7">
								  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
									<rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
									</rect>
									<rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									</rect>
									<rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									</rect>
								  </svg>
								</div>
							</div>  

						</div>


						<div class="col-6 col-md-3 p-2" *ngFor="let product of publicProducts?.publicProductData; let i = index">
							<app-product-card 
								isHome="Y"
								[images]="product?.imagesUrl"
								[title]="product?.productName"
								[company]="product?.vendorName"
								[currency]="product.currency?product.currency:'IDR'"
								[price]="product?.productPrice"
								location="{{ product.vendorCity? product.vendorCity+', ':''}}{{ product.vendorCountry}}"
								[isConfidential]="product.vendorName?'Y':'N'"
								url="/product/{{(product.companyDomain)?product.companyDomain:'view/'+product.vendorId}}/{{product.productSlugUrl}}"
							>
							</app-product-card>
						</div>

					</div>
				</div>
				<div class="col-lg-5">
					<div class="row align-items-center divTemplate">
						<div class="col-5">
							<h5>RFQ Terbaru</h5>
						</div>
						<div class="col-7 text-end">
							<a class="pointer" href="/regis">Tambahkan RFQ anda</a>
						</div>
						<div class="col-12">
							<hr>
						</div>
						<div class="col-12" *ngIf="publicRFQLoading">

							<div class="text-center pt-5 pb-5">
								<div class="loader loader--style8" title="7">
								  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
									<rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
									</rect>
									<rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									</rect>
									<rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									</rect>
								  </svg>
								</div>
							</div>  

						</div>

						<div class="col-12" *ngIf="!publicRFQLoading">
							<div class="py-2">
								<ng-container *ngFor="let rfqboard of dataRfqBoard; let i = index">
									<app-board [rfq]="rfqboard" [forceMobile]="true"></app-board>
								</ng-container>
							</div>


						</div>
						
					</div>
				</div>
			</div>
		</div>
	</section>
</ng-container>



<section id="manfaat-vendor" class="section has-padding section-vendor">
	<div class="main-container">
		<div class="row">
			<div class="col-12 text-center">
				<h2 class="mt-5 cd1 cdtxtshadow mb-0">Manfaat dan Layanan untuk <span class="cd2 cdtxtshadow">VENDOR</span></h2>
				<p class="ngc-intro mb-4 pb-3">
					Lebih cepat dapat Customer karena sistem kami menggunakan AI untuk terus menerus mencarikan prospek untuk Anda.
				</p>
			</div>

			<div class="col-12 px-4 px-sm-2"   data-aos="fade-in">
				<div class="row cd-vendor-section">
					<div class="col-sm-12 col-md-6 col-lg-5 order-1 order-md-2">
						<div class="image-vendor" style="min-height: 300px;">
							<img src="/assets/homepage/images/landing-page/vendor-bg.jpg" alt="image caption" loading="lazy">
						</div>
					</div>
					<div class="col-sm-12 col-md-6 col-lg-7 order-2 order-md-1">
						<div class="row  p-3 p-lg-5">
							<div class="col-lg-6 d-flex align-items-stretc"   data-aos="fade-right" data-aos-delay="100">
								<div class="p-3">
									<div class="cd-box-vendor">
										<div class="d-flex align-items-center">
											<span  class="py-0"><img src="/assets/homepage/images/landing-page/vendor1.jpg" style="max-width: 52px;"></span>
											<h5 class="m-0 px-2">Didukung oleh AI</h5>
										</div>

										<p class="mb-2 mt-0">Cepetdapet memakai AI untuk mencocokan produk yang Anda jual terhadap RFQ (Request for Quotation) yang di publikasikan oleh Customer (Procurement).</p>
									</div>
								</div>
							</div>
							<div class="col-lg-6 d-flex align-items-stretc" data-aos="fade-left" data-aos-delay="200">
								<div class="p-3">
									<div class="cd-box-vendor">
										<div class="d-flex align-items-center">
											<span  class="py-0"><img src="/assets/homepage/images/landing-page/vendor2.jpg" style="max-width: 52px;"></span>
											<h5 class="m-0 px-2">Segudang Fitur Pintar</h5>
										</div>
										<p class="mb-2 mt-0">Cepetdapet juga memiliki fitur Autobid, Market Intelligence, RFQ Matching System yang akan memudahkan transaksi Anda.</p>
									</div>
								</div>
							</div>
							<div class="col-lg-6 d-flex align-items-stretc" data-aos="fade-right" data-aos-delay="300">
								<div class="p-3">
									<div class="cd-box-vendor">
										<div class="d-flex align-items-center">
											<span  class="py-0"><img src="/assets/homepage/images/landing-page/vendor3.jpg" style="max-width: 52px;"></span>
											<h5 class="m-0 px-2">Mempermudah Procurement</h5>
										</div>
										<p class="mb-2 mt-0">Dengan Cepetdapet, Procurement lebih mudah dan cepat untuk menerima penawaran dari Vendor seperti Anda.</p>
									</div>
								</div>
							</div>
							<div class="col-lg-6 d-flex align-items-stretc" data-aos="fade-left" data-aos-delay="400">
								<div class="p-3">
									<div class="cd-box-vendor">
										<div class="d-flex align-items-center">
											<span  class="py-0"><img src="/assets/homepage/images/landing-page/vendor4.jpg" style="max-width: 52px;"></span>
											<h5 class="m-0 px-2">Berbasis Cloud</h5>
										</div>
										<p class="mb-2 mt-0">Mudah dan cepat digunakan dimanapun agar dapat lebih banyak RFQ.</p>
									</div>
								</div>
							</div>

							<div class="col-12">
								<div class="p-2 my-2" data-aos="fade-up" data-aos-delay="500">
									<a class="btn-ceda-second text-white px-3 py-2 rounded-5" href="/help/tentang-vendor">Pelajari Tentang Vendor</a>
								</div>
							</div>

						</div>
					</div>


				</div>
			</div>

		</div>


	</div>
</section>

<section id="manfaat-procurement" class="section has-padding section-procurement">
	<div class="main-container">
		<div class="row  px-2">
			<div class="col-sm-1"></div>
			<div class="col-sm-10">
				<div class="text-center mb-4">
					<h2 class="mt-5 cd1 cdtxtshadow mb-0">Manfaat dan Layanan untuk <span class="cd2 cdtxtshadow">PROCUREMENT</span></h2>
				</div>
				
				<div class=" p-3 mb-3"  data-aos="fade-in" data-aos-delay="50">
					<div class="cd-vendor-section row">
						<div class="col-12 col-md-6">

							<div style="width: 100%;
							height: 100%;
							object-fit: cover;" class="img-proc">

								<div class="img-proc-content" style=" 
								background-image: url('/assets/homepage/images/landing-page/proc-bg.jpg'); 
								background-size: cover; 
								background-repeat: no-repeat;
								background-position: center; 
								height: 100%;
								min-height: 400px; 
								">
									

								</div>

							</div>


	
						</div>
						<div class="col-12 col-md-6 bgcd3 bgcd3rounded">
							<div class="p-3 h-100">
								<div class="row align-items-center">
								
									<div class="col-12">
										
										<div class="py-5 my-md-5">
											<div class="px-4 pb-3">
												<p class="cd1"  data-aos="fade-in" data-aos-delay="100">Cara mudah dan cepat untuk mencari penawaran. Cukup posting RFQ satu kali, dan sistem kami akan mencarikan Vendor untuk Anda.</p>
												<a class="btn-ceda-second text-white px-3 py-2 rounded-5" href="/help/tentang-procurement"  data-aos="fade-in" data-aos-delay="200">Pelajari Tentang Procurement</a>
											</div>
										</div>
									</div>
								</div>
							</div>
		
						</div>
					</div>
				</div>
	
	
				<div class="row g-0">
					<div class="col-lg-4 d-flex align-items-stretch"  data-aos="fade-right" data-aos-delay="200">
						<div class="p-3">
							<div class="cd-box-vendor">
								<div class="d-flex align-items-center">
									<span  class="py-0"><img src="/assets/homepage/images/landing-page/proc1.jpg" style="max-width: 52px;"></span>
									<h5 class="m-0 px-2">Menghemat waktu anda</h5>
								</div>
								<p class="mb-2 mt-0">Tidak lagi menghubungi Vendor satu persatu, Anda hanya perlu membuat satu RFQ (Request For Quotation) dan banyak Vendor akan mengirimkan Quotation.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 d-flex align-items-stretch"  data-aos="fade-up" data-aos-delay="250">
						<div class="p-3">
							<div class="cd-box-vendor">
								<div class="d-flex align-items-center">
									<span  class="py-0"><img src="/assets/homepage/images/landing-page/proc2.jpg" style="max-width: 52px;"></span>
									<h5 class="m-0 px-2">Algoritma Berbasis AI</h5>
								</div>
								<p class="mb-2 mt-0">Algoritma Cepetdapet sudah menggunakan AI untuk mencarikan Vendor yang cocok untuk Anda dan interface kami akan mempermudah dalam membandingkan dan memilih Vendor.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 d-flex align-items-stretch"  data-aos="fade-left" data-aos-delay="300">
						<div class="p-3">
							<div class="cd-box-vendor">
								<div class="d-flex align-items-center">
									<span  class="py-0"><img src="/assets/homepage/images/landing-page/proc3.jpg" style="max-width: 52px;"></span>
									<h5 class="m-0 px-2">Tanpa Biaya Komisi</h5>
								</div>
								<p class="mb-2 mt-0">Cepetdapet bertujuan mempertemukan Anda dengan Vendor, dan mempermudah Anda dalam bertransaksi. Anda bebas bertransaksi di luar sistem, setelah menemukan Vendor yang cocok. Kami tidak menarik komisi atas transaksi Anda. Dengan begitu, Vendor dapat menawarkan harga yang terbaik untuk Anda.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-1"></div>
		</div>
	</div>


</section>




<section id="bukan-marketplace" class="section section-marketplace">
	<div class="main-container">

		<div class="text-center mb-4">
			<h2 class="mt-5 cd1 cdtxtshadow mb-0">Cepetdapet <span class="cd2 cdtxtshadow">Bukanlah</span> Market Place</h2>
		</div>



		<div class="flow-market">
			<picture class="flow-image"  data-aos="zoom-in">
				<source media="(max-width: 760px)" srcset="/assets/homepage/images/landing-page/mobile.png">
				<source media="(min-width: 761px)" srcset="/assets/homepage/images/landing-page/web.png">
				<img
					srcset="/assets/homepage/images/landing-page/web.png, 
						/assets/homepage/images/landing-page/web.png"
					src="/assets/homepage/images/landing-page/web.png" 
					width="1000" height="400"
					alt="image caption here">
			</picture>

	</div>
	</div>

	<div class="divhomeVideo">


		<div class="text-center">
			<p class="cd1 mb-0" data-aos="fade-up" data-aos-delay="100">
				Cepetdapet bukanlah marketplace. Kami tidak menerima fee dari transaksi anda.
			</p>
			<p class="cd1" data-aos="fade-up" data-aos-delay="200">
				Dengan misi, memberikan dan mempertemukan baik vendor dan customer agar mendapatkan harga terbaik!
			</p>
		</div>

	</div><!-- .main-container -->

</section>



<section class="section-search" style="background-color: #fee1d3; display: none;">
	<div class="main-container">
		<h2 class="mt-3 mb-3">Cari produk yang Anda butuhkan:</h2>
		<form class="form-search">
			<div class="input-wrap">
				<input type="text" placeholder="Ketik nama produk di sini" required class="input-text" [(ngModel)]="valSearch" (keydown.enter)="globalSearch()" [ngModelOptions]="{standalone: true}" [disabled]="loadingSearch">
				<button class="btn-submit" aria-label="Search button" (click)="globalSearch()" [disabled]="loadingSearch">
					<span *ngIf="!loadingSearch" class="fas fa-search icon"></span>
					<span *ngIf="loadingSearch" class="fas fa-spinner fa-pulse"></span>
				</button>
				<div class="spinner-border text-secondary" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div><!-- .input-wrap -->
			<small class="fst-italic" *ngIf="invalidSearch"><i class="text-danger">Please type keyword minimum 3 character.</i></small>
		</form>
		


	</div><!-- .main-container -->
</section>




<section class="section pt-0" style="background-color: #fff;">
	<div class="main-container">



		<div class="pt-3 pt-sm-5 mt-2 mt-sm-5 mb-0 pb-1 px-sm-5 pt-0" id="homeVideo">

			<div class="mb-4 mb-sm-1">
				<div class="row">
					<div class="col-sm-1"></div>
					<div class="col-sm-10">

						<div class="text-center mb-4 videoTitle">
							<h2 class="mt-0 cd1 cdtxtshadow mb-0">Solusi cepet dapet  <span class="cd2 cdtxtshadow">Vendor</span> dan <span class="cd2 cdtxtshadow">Procurement</span></h2>
						</div>

						<p class="mb-0 cd1  py-3">Bergabunglah dengan Cepetdapet dan temukan peluang baru dalam proses pengadaan.
							Tonton video kami sekarang dan temukan mengapa Cepetdapet adalah platform pilihan untuk pertemuan yang lebih baik antara Vendor dan Procurement.</p>
					</div>
					<div class="col-sm-1"></div>
				</div>
			</div>



		</div>


	</div>
	<div class="divhomeVideo"  data-aos="fade-in" data-aos-delay="200">


		

		<div class="px-sm-5">
			<div class="main-container">
				<div class="p-0 p-sm-5 pt-4 pt-sm-2 ">
					<div class="row">
						<div class="col-sm-1"></div>
						<div class="col-sm-10 p-sm-4">
							<div id="mtmHomeYoutube" class="ratio ratio-16x9 mtmHomeYoutube">
								<iframe id="mtmHomeIframeYoutube" class="bgcd1 p-3 p-sm-4 border-4 mtmHomeIframeYoutube" style="z-index: 2; border-radius: 10px;" src="https://www.youtube.com/embed/4YwalW5yHqQ?si=TFRRnN2w7kiQ0FFI&rel=0" title="CepetDapet YouTube video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
							</div>
						</div>
						<div class="col-sm-1"></div>
					</div>

				</div>

			</div>
		</div>

	</div>

		
	
</section>

<section id="subscription"  class="bgcd3 py-5"  data-aos="fade-in" data-aos-delay="200">
	<div class="main-container my-2">
		<div class="row">
			<div class="col-sm-9">
				<div class=""   data-aos="fade-in" data-aos-delay="300">
					<h1 class="mt-0 cd1 cdtxtshadow mb-0">Berlangganan fitur <span class="cd2 cdtxtshadow">Premium</span></h1>
					<p class="cd1 my-0 py-0">Pilih Plan sesuai kebutuhan anda dan dapatkan benefit dari berlangganan fitur premium</p>
				</div>
			</div>
			<div class="col-sm-3">
				<div class="p-2 my-2 text-end">
					<a class="btn-ceda-second text-white px-3 py-2 rounded-5" href="/subscription"  data-aos="fade-in" data-aos-delay="450">Berlangganan Sekarang</a>
				</div>
			</div>
		</div>
	</div>

</section>

<div class="fixed-bottom bg-white d-block d-md-none"> <!-- *ngIf="!isLoggedin" --> 
	<div class="main-container">
		<div class="row">
			<div class="col-6">
				<div class="p-2">
					<a href="/login" *ngIf="!isLoggedin" class="btn btn-cta btn-ghost py-2 w-100">Login</a>
					<a href="/logout" *ngIf="isLoggedin" class="btn btn-cta btn-ghost py-2 w-100">Login</a>
				</div>
	
			</div>
			<div class="col-6">
				<div class="p-2">
					<a href="/regis" class="btn py-2 btn-cta w-100">Register</a>
				</div>
			</div>
		</div>
	</div>
</div>

<button
  *ngIf="showButton"
  (click)="scrollToTop()"
  class="scroll-to-top-button"
>
<i class="fas fa-solid fa-arrow-up"></i>
</button>


<footer id="web-footer" class="bgcd4">
	<div class="main-container">
		<div class="footer-top">
			<div class="footer-child child-1 d-none">
				<img src="/assets/homepage/images/logo-white-200.png" srcset="/assets/homepage/images/logo-white-200.png 200w, /assets/homepage/images/logo-white-400.png 400w" alt="Cepetdapet" loading="lazy" width="200" height="52" class="footer-logo">
			</div><!-- .footer-child -->
			<div class="footer-child child-2 d-none">
				<h3 class="ngc-title">Hubungi Kami</h3>
				<ul class="contact-info">
					<li>
						<span class="fas fa-map-marker-alt icon"></span>
						<span>Perkantoran ABC , Jl. Mawar Melati, Blok A no 1-2 Jakarta Barat - Indonesia</span>
					</li>
					<li>
						<span class="fas fa-phone icon flip-x"></span>
						<a href="tel:0215554433">(021) 555 4433</a>
					</li>
					<li>
						<span class="fas fa-envelope icon"></span>
						<a href="mailto:info@cepetdapet.com">info@cepetdapet.com</a>
					</li>
				</ul><!-- .contact-info -->
			</div><!-- .footer-child -->
			<div class="footer-child child-3 d-none">
				<h3 class="ngc-title">Links</h3>
				<ul class="footer-nav">
					<li>
						<a href="index.html">Homepage</a>
					</li>
					<li>
						<a href="">Syarat & Ketentuan</a>
					</li>
					<li>
						<a href="">Report & Feedback</a>
					</li>
					<li>
						<a href="#section-faq">Pertanyaan Yang Sering Diajukan</a>
					</li>
					<li>
						<a href="/help">Help</a>
					</li>
					<li>
						<a href="">Subscription</a>
					</li>
				</ul><!-- .footer-nav -->
			</div><!-- .footer-child -->

				<div class="row p-4 lh-lg w-100">
					<div class="col-sm-5">
					  <img src="/assets/images/logofh-cepetdapet.png" style="max-width:220px;" class="logofooter">

					  <ul class="socmed-nav pt-0">
						<li>
							<a href="https://www.facebook.com/cepetdapetcom/" target="_blank" class="soc-fb" aria-label="Visit our Facebook"><span class="fab fa-facebook-f icon"></span></a>
						</li>
						<li>
							<a href="https://www.linkedin.com/company/cepetdapet" target="_blank" class="soc-linkedin" aria-label="Visit our LinkedIn"><span class="fab fa-linkedin-in icon"></span></a>
						</li>
						<li>
							<a href="https://www.instagram.com/cepetdapetsegala/" target="_blank" class="soc-ig" aria-label="Visit our Instagram"><span class="fab fa-instagram icon"></span></a>
						</li>
						<li>
							<a href="https://www.youtube.com/@CepetDapet" target="_blank" class="soc-youtube" aria-label="Visit our Youtube channel"><span class="fab fa-youtube icon"></span></a>
						</li>

					  </ul>

					</div>
					<div class="col-sm-7">
					  <div class="row g-0">
						<div class="col-sm-6">
						  <!-- <strong>Tautan</strong><br> -->
						  <a class="listfooter" href="/">{{'menu.home'|translate}}</a><br>
						  <a class="listfooter pointer" (click)="sendMessage()">{{'menu.report_feedback'|translate}}</a><br>
						  <a class="listfooter" href="/subscription">{{'menu.subscription'|translate}}</a><br>
						</div>
						<div class="col-sm-6">
						  <!-- <br> -->
						  <a class="listfooter pointer" (click)="openVerticallyCentered(content)">{{'menu.tos'|translate}}</a><br>
						  <a class="listfooter" href="/help">{{'menu.help'|translate}}</a><br>
						  <a class="listfooter" href="/contact-us">{{'menu.contact_us'|translate}}</a><br>
						</div>
					  </div>
					</div>
			
				  </div>


		</div><!-- .footer-top -->

	</div><!-- .main-container -->
</footer>
<!-- <div class="text-center">
	<div class="my-2">
		<small class="copyright">Copyright &copy; {{ currentYear }} Cepetdapet. All Rights Reserved.
		</small>
	</div>
</div> -->
<div class="text-center d-none d-md-block">
	<div class="my-2">
		<small class="copyright">Copyright &copy; {{ currentYear }} Cepetdapet. All Rights Reserved.
		</small>
	</div>
</div>
<div class="text-center d-block d-md-none footerMobile">
	<div class="my-2">
		<small class="copyright">Copyright &copy; {{ currentYear }} Cepetdapet. All Rights Reserved.
		</small>
	</div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title px-3">Term & Conditions</h4>
  </div>
	<div class="modal-body">
		<div class="pt-3 pb-3 pe-4 ps-4 border-1">

				<div  *ngIf="TnCsrc" >
					<pdf-viewer 
					[src]="TnCsrc" 
					[original-size]="false"
					[show-all]="true"
					[fit-to-page]="false"
					[zoom]="1"
					[zoom-scale]="'page-width'"
					[stick-to-page]="false"
					[render-text]="true"
					[external-link-target]="'blank'"
					[autoresize]="true"
					[show-borders]="false"
					style="width: 100%; height: 400px;"
					>
					</pdf-viewer>
				</div>

		</div>
	</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light mb-3 mx-3" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>

<div *ngIf="templateId!=='0'">
	<!-- <h1 id="templateId">{{templateId |json}}</h1> -->
	<input type="hidden" id="templateId" value="{{templateId}}">
</div>

