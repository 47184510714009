import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { gql, Apollo } from 'apollo-angular';
import { AuthService } from 'src/app/auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { MessagingService } from 'src/app/_services/messaging.services';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import Util from 'src/app/_helpers/util';
import { CompanyService } from 'src/app/_services/company.services';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/_services/data';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { OthersService } from 'src/app/_services/others.service';


const mutationLogout = gql`
mutation logout($leId: ID!) {
  logout(input: {
      leId: $leId
  })
}
`;

// const mutationChooseCompany = gql`
// mutation chooseCompany($companyId: ID!, $loginEventId: ID!) {
//   chooseCompany(input: {companyId: $companyId,  loginEventId: $loginEventId}) {
//       __typename,
//       ...on ChooseCompanyResponse {
//           isFirstTimeLogin,
//           staff {
//               id,
//               roleMembership,
//               isActive,
//               companyId,
//               createdDate,
//               createdBy,
//               modifiedDate,
//               modifiedBy
//           },
//           accessToken
//       }
//       ...on DomainError {
//           errorMessage
//       }
//       ...on UnacceptedTermConditionError {
//           latestTermCondition {
//               id
//           }
//       }
//   }
// }
// `;

const getCompanyCurrency = gql`
query{
  company{
    defaultCurrency
  }
}
`;

@Component({
  selector: 'app-memberheader',
  templateUrl: './memberheader.component.html',
  styleUrls: ['./memberheader.component.scss']
})
export class MemberheaderComponent implements OnInit {
  PageLoading: boolean = false
  jwttoken: any
  jwtreftoken: any
  userdata: any
  getcompany: any

  getcompanyName: any = ''
  getcompanyID: any
  display = 'none'
  emptyCompany: boolean = false
  singleCompany: boolean = true
  errChooseCompany: string = ''
  confirmLoading: boolean = false
  ValCompany: any = ''
  ValCompanyName: any
  ArrCompany = []
  notYetSelectCompany: boolean = true
  sidebar: boolean = false
  roleId: string = '0'
  sidebarmenu: any = []
  showtotop: boolean = false
  usercompany: any;
  roleName: string = '';
  currentlang:any

  dispaymenu: any = []
  hidechildmenu: any = []

  valSearch:string=''
  totalMessages:number=0
  totalNotification:number=0

  invalidSearch:boolean=false

  totalCart:number=0

  public href: string = "";
  isHomePage: boolean=false;

  public getScreenWidth: any;
  isMobile:boolean=false

  latestTermCondition:string=''
  needConfirmTos:boolean=false
  message:string | undefined;
  subscription: Subscription | undefined;

  companyLogo:any='/assets/images/default-company.jpeg'

  closeResult:string=''
  @ViewChild('content') myModal : any;
  constructor(
    private authService: AuthService,
    private router: Router,
    private apollo: Apollo,
    public translate: TranslateService,
    private modalService: NgbModal,
    private messagingService: MessagingService,
    private popupmodal : Popupmodal,
    private companyService: CompanyService,
    private data: DataService,
    private route: ActivatedRoute,
    private othersService: OthersService
  ) { }

  ngOnInit(): void {
    console.log('myModal',this.myModal)
    this.currentlang = localStorage.getItem('language');
    
    this.jwttoken = this.parseJwt(localStorage.getItem("JWT_TOKEN"))
    console.log('this.jwttoken',this.jwttoken);

    this.jwtreftoken = this.parseJwt(localStorage.getItem("REFRESH_TOKEN"))
    console.log(this.jwtreftoken);

    this.loadProfile()
    this.getMenu()
    // this.getCurrency()

    if(!this.notYetSelectCompany){
      this.getTotalMessages()
      this.getCart()
    }
    
    this.href = this.router.url;
    console.log('this.href : ',this.href);

    if(this.href=='/'){
      this.isHomePage=true
    }

    

    this.getScreenWidth = window.innerWidth;
    if(this.getScreenWidth<575){
      this.isMobile=true
    } else {
      this.isMobile=false
    }

    const fullUrl = this.router.url; // Get the full URL
    const urlSegments = fullUrl.split('/').filter(segment => segment); // Split and filter empty segments
    console.log("urlSegments : ",urlSegments)

    if (urlSegments.length > 0) {
      if(urlSegments[0]=="search"){

        var getketword  = this.route.snapshot.paramMap.get('keyword');
        console.log('getketword',getketword);
        const lastUrlSegment = this.router.url.split('?')[0].split('/').pop()
        console.log('>> ',lastUrlSegment)
        if(lastUrlSegment){
          this.valSearch = decodeURIComponent(lastUrlSegment)
        }

      }
    }

  }
  ngAfterViewInit() {
    if (localStorage.getItem("COMPANYSELECTID") === null) {
      if(!this.singleCompany){
        this.open();
      }

    }
    if(localStorage.getItem("COMPANY_LOGO") === null){
      if(!this.notYetSelectCompany){
        this.getLogo()
      }
    } else {
      this.companyLogo = localStorage.getItem("COMPANY_LOGO")
    }
 }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    if(this.getScreenWidth<575){
      this.isMobile=true
    } else {
      this.isMobile=false
    }
  }

  fullMenu(){
    const modalRef = this.modalService.open(ModalComponent, {
      modalDialogClass: 'modal-dialog-centered modal-fullscreen',
      centered: true,
      backdrop : 'static',
      keyboard : false,
      size: 'sm'
    })
    modalRef.componentInstance.modalMenu = 'menu'
    modalRef.componentInstance.modalCompanyName = this.getcompanyName
    modalRef.componentInstance.modalFullName = this.userdata.fullname
    modalRef.componentInstance.modalisLogin = true
    
    modalRef.componentInstance.confirmedClick.subscribe((btn: any) => {
      if(btn=='company'){
        //this.ChangeCompany()
        this.open()
      }
      if(btn=='profile'){
        this.router.navigate(['/profile']);
      }
      if(btn=='logout'){
        this.logout()
      }
    })

  }



  getCurrency(){

    var getdefaultCurrency = localStorage.getItem("defaultCurrency")
    if (getdefaultCurrency == null) {

      if(!this.notYetSelectCompany){
        this.apollo.watchQuery<any>({
          query: getCompanyCurrency
        })
          .valueChanges
          .subscribe(({ data }) => {
            console.log('data defaultCurrency', data);
            if(data.company?.defaultCurrency){
              localStorage.setItem("defaultCurrency",data.company.defaultCurrency)
            }
            
          }) 
      }

    }

  }

  hideshowmenu() {
    if (!this.sidebar) {
      this.sidebar = true
    } else {
      this.sidebar = false
    }
  }

  getMenu() {
    console.log('this', this)
    //this.sidebarmenu =['1','2','3']


    this.sidebarmenu = [
      // {
      //   'id': 1,
      //   'icon': '/assets/images/icons/lucide_layout-dashboard.png',
      //   'url': '/dashboard',
      //   'text': 'Dashboard',
      //   'parent': 0
      // },
      {
        'id': 2,
        'icon': '/assets/images/icons/mdi_shopping-outline.png',
        'url': '/vendor/product-list',
        'text': 'Products',
        'parent': 0
      },
      {
        'id': 3,
        'icon': '/assets/images/icons/mdi_form-select.png',
        'url': '',
        'text': 'Procurement Modules',
        'parent': 0
      },
          {
            'id': 5,
            'icon': '/assets/images/icons/uil_server.png',
            'url': '/procurement/rfq-list',
            'text': 'My RFQ',
            'parent': 3
          },
          {
            'id': 6,
            'icon': '/assets/images/icons/ic_outline-request-quote.png',
            'url': '/procurement/rfq/my-proforma-po',
            'text': 'My Proforma PO',
            'parent': 3
          },
          {
            'id': 7,
            'icon': '/assets/images/icons/reccuring.jpg',
            'url': '/procurement/rfq/recurring',
            'text': 'Recurring RFQ',
            'parent': 3
          },
          {
            'id': 8,
            'icon': '/assets/images/icons/history.jpg',
            'url': '/procurement/rfq-history',
            'text': 'RFQ History',
            'parent': 3
          },
          {
            'id': 12,
            'icon': '/assets/images/icons/iconoir_page-star.png',
            'url': '/procurement/rating/procurement-rating-list',
            'text': 'Rating & Review',
            'parent': 3
          },
      {
        'id': 4,
        'icon': '/assets/images/icons/mdi_form-select.png',
        'url': '',
        'text': 'Vendor Modules',
        'parent': 0
      },
          {
            'id': 9,
            'icon': '/assets/images/icons/uil_server.png',
            'url': '/vendor/rfq-board',
            'text': 'RFQ Board',
            'parent': 4
          },
          {
            'id': 10,
            'icon': '/assets/images/icons/ic_outline-request-quote.png',
            'url': '/vendor/rfq-participating-in-list',
            'text': 'Participating In',
            'parent': 4
          },
          {
            'id': 11,
            'icon': '/assets/images/icons/history.jpg',
            'url': '/vendor/rfq-participating-in/history',
            'text': 'History',
            'parent': 4
          },
          {
            'id': 14,
            'icon': '/assets/images/icons/iconoir_page-star.png',
            'url': '/vendor/rating/rating-list',
            'text': 'Rating & Review',
            'parent': 4
          },
      {
        'id': 13,
        'icon': '/assets/images/icons/uil_setting.png',
        'url': '/setting/company-management',
        'text': 'Company Management',
        'parent': 0
      },


    ]


  }

  parseJwt(token: string | null) {
    if (token !== null) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }

  };

  getRoleName(id:any){
    var Rolename =''
    if(id=='32'){
      Rolename='Procurement Staff'
    } 
    else if(id=='16'){
      Rolename='Vendor Staff'
    }
    else if(id=='8'){
      Rolename='Procurement Manage'
    }
    else if(id=='4'){
      Rolename='Vendor Manager'
    }
    else if(id=='2'){
      Rolename='Senior Manager'
    }
    else if(id=='1'){
      Rolename='Owner'
    } else {
      Rolename='Procurement Staff'
    }
    return Rolename
  }

  loadProfile() {
    var getuserdata = localStorage.getItem("USERDATA")
    if (getuserdata !== null) {
      this.userdata = JSON.parse(getuserdata);
      console.log('getuserdata', this.userdata);
    }

    var getusercompany = localStorage.getItem("USERCOMPANY")
    if (getusercompany !== null) {
      this.usercompany = JSON.parse(getusercompany);
      console.log('getusercompany', this.usercompany);

      this.roleName = this.getRoleName(this.usercompany.roleMembership)

    }

    var company = localStorage.getItem("COMPANYDATA")
    if (company !== null) {
      this.getcompany = JSON.parse(company);
      console.log('element', this.getcompany[0]['companyName']);
    }

    if (localStorage.getItem("COMPANYSELECTID") !== null) {
      this.getcompanyName = localStorage.getItem("COMPANYSELECTNAME")
      this.getcompanyID = localStorage.getItem("COMPANYSELECTID")
      this.notYetSelectCompany = false
    } else {
      this.notYetSelectCompany = true
    }

    var getcompany = localStorage.getItem("COMPANYDATA")

    if (getcompany !== null) {

      this.ArrCompany = JSON.parse(getcompany);
      console.log(this.ArrCompany);

      if (this.ArrCompany.length > 1) {
        this.singleCompany = false
        console.log('this.singleCompany', this.singleCompany);


        if (localStorage.getItem("COMPANYSELECTID") === null) {
          //this.ChangeCompany() //open modal to choose company
          //this.open()
        }

      } else {
        this.ValCompany = this.ArrCompany[0]['companyId']
        this.ValCompanyName = this.ArrCompany[0]['companyName']
        
        //handle loop refresh location.reload()
        if (localStorage.getItem("COMPANYSELECTID") === null) {
          this.ConfirmCompany()
        }
        console.log('this.singleCompany', this.singleCompany);
      }
    }

    // this.getcompany.forEach((value: any, key: any) => {
    //   console.log('element',value);
    // })
  }

  logout() {
    var leId = this.jwtreftoken['leid']

    this.apollo
      .mutate({
        mutation: mutationLogout,
        variables: { leId: leId }
      })
      .subscribe(
        (res: any) => {
          console.log("succes, logout:", res);
          if (res.data) {
            console.log("redirect to login");
            this.authService.forceLogout();
            this.router.navigate(['/login']);
          }
        },
        error => {
          console.log("there was an error sending the logout", error);
  
          const modalRef = this.modalService.open(ModalComponent, {
            modalDialogClass: 'modal-dialog-centered',
            centered: true,
            backdrop : 'static',
            keyboard : false,
            size: 'sm'
          })
          modalRef.componentInstance.modalinfo = 'info'
          modalRef.componentInstance.infoTitle = 'Opps Something Wrong!' // 'Success!' 
          modalRef.componentInstance.infoSuccess = null
          modalRef.componentInstance.infoFailed = 'Please try again later'
          modalRef.componentInstance.closeTxt = 'Close'
  
          modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
            if (res){
              this.router.navigate(['/']);
            } 
          })

        }
      );
  }

  ChangeCompany() {
    console.log('openModal >> ', this.singleCompany);
    if (this.display == "none") {
      this.display = "block";
    } else {
      this.display = "none";
    }
    this.emptyCompany = false;


  }
  ConfirmCompany() {
    console.log('confirm company', this.ValCompany);
    if (this.ValCompany !== '') {

      var companyId = this.ValCompany
      var loginEventId = this.jwtreftoken['leid']

      this.confirmLoading = true
      this.errChooseCompany = ''

      this.emptyCompany = false


      this.companyService.getmutationChooseCompany(companyId,loginEventId).subscribe((res:any) => {
        console.log(res)
    
        if(res.data?.chooseCompany){

          localStorage.removeItem("COMPANY_LOGO")
          //this.getLogo()
          
          console.log("succes, get data:", res);


          this.confirmLoading = false
          this.PageLoading = false


          this.ArrCompany.forEach((val: any, k: any) => {
            console.log('ArrCompany v : ', val['companyId'])
            console.log('ArrCompany v : ', val.companyId)
            console.log('ArrCompany k : ', k)
            if (this.ValCompany == val.companyId) {
              this.ValCompany = this.ArrCompany[k]['companyId']
              this.ValCompanyName = this.ArrCompany[k]['companyName']
            }

          })
          console.log('ValCompany', this.ValCompany);
          console.log('ValCompanyName', this.ValCompanyName);
          localStorage.setItem("COMPANYSELECTID", this.ValCompany)
          localStorage.setItem("COMPANYSELECTNAME", this.ValCompanyName)

          // saya potong hingga 10 karakter karena jika terlalu panjang akan menambah height member header
          this.getcompanyName = this.ValCompanyName

          this.notYetSelectCompany = false


          //if any update TOS
          if(res.data?.chooseCompany?.latestTermCondition?.id){

            this.latestTermCondition = res.data?.chooseCompany.latestTermCondition.id
            this.needConfirmTos=true
            localStorage.setItem("NEEDCONFIRMTOS", 'Y')
            localStorage.setItem("LATESTTOS", this.latestTermCondition)
    
            this.data.changeMessage('reloaddashboard')
            this.subscription = this.data.currentMessage.subscribe(message => this.message = message)
            console.log('memberheader this.message',this.message);
            console.log('memberheader this.subscription',this.subscription);
          } else {
            localStorage.removeItem("NEEDCONFIRMTOS")
            localStorage.removeItem("LATESTTOS")
          }

          if (res.data.chooseCompany.staff) {

            this.modalService.dismissAll()


            localStorage.removeItem("USERCOMPANY")
            localStorage.setItem("USERCOMPANY", JSON.stringify(res.data.chooseCompany.staff))
            // dipindah kesini agar saat getMenu roleMembership nya bisa ketahuan
            // semula this.usercompany selalu null walaupun sudah diambil dari localStorage USERCOMPANY
            this.usercompany = res.data.chooseCompany.staff;
            this.getMenu()
            this.getCurrency()

            if (res.data.chooseCompany.staff.roleMembership == 1) {
              this.roleName = 'Owner'
            }
            else if (res.data.chooseCompany.staff.roleMembership == 2) {
              this.roleName = 'S. Manager'
            }
            else if (res.data.chooseCompany.staff.roleMembership == 4) {
              this.roleName = 'Manager'
            } else {
              this.roleName = 'Staff'
            }

            localStorage.setItem("JWT_TOKEN", res.data.chooseCompany.accessToken)

            if (res.data.chooseCompany.isFirstTimeLogin==true && res.data.chooseCompany.staff.roleMembership == 1) {
              //redirect to subscription plan / show subcription plan
              //this.subscription=true
              this.display = "none";
              //this.PageLoading=true
              //alert('redirect to company settings..');
              this.router.navigate(['/setting/company-detail']);
              return
            }

            //this.display = "none";//close modal
            this.confirmLoading = true


            // //--redirect dashboard--//
            // var getusercompany = localStorage.getItem("USERCOMPANY")
            // var redirect = '/';
            // console.log('redirectDasboard 2 : ', usercompany);
            // if (getusercompany !== null) {
            //   var usercompany = JSON.parse(getusercompany);
            //   redirect = Util.redirectDasboard(usercompany.roleMembership)

            //   if(this.router.url == redirect){
            //     location.reload() 
            //   } else {
            //     //this.router.navigate([redirect]);
            //     window.location.replace(redirect);
            //   }

            // } else {
            //   //this.router.navigate([redirect]);
            //   window.location.replace(redirect);
            // }
            // return
            // //--redirect dashboard--//

           
            //window.location = "http://www.yoururl.com";
            if(this.router.url=='/dashboard'){
              location.reload() 
            } else {
              this.router.navigate(['/dashboard']);
            }
            return
            if(this.router.url=='/'){
              location.reload() 
            } else {
              this.router.navigate(['/']);
            }
            //location.reload()          
            
          } else {
            //alert('reload')
            this.modalService.dismissAll()
            location.reload()
          }
        }
        else if(res.errors){
          var error = res.errors[0].message
          console.log('error',error);
          var errMsg = error + '';
          var result = errMsg.split(":");
          var resMsg = result[result.length - 1];
          var msg = (resMsg)? resMsg: errMsg
          console.log('msg',msg);
          var errortext = msg
  
          if(res.errors[0].extensions.type){
            //get translate error, if exsit, replace errortext
            this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
              if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
                errortext = data
              }
            });
            //force redirect on specific errortype
            var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
            if(forceRedirect){
              window.location.href = forceRedirect
              return
            }
          }
          this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
          this.confirmLoading = false
          this.errChooseCompany = error
          this.display = "none";
        }
        else {
          this.popupmodal.showFail().subscribe((res:any) => {})
        }
        
      });

      return


      // this.apollo
      //   .mutate({
      //     mutation: mutationChooseCompany,
      //     variables: { companyId: companyId, loginEventId: loginEventId }
      //   })
      //   .subscribe(
      //     (res: any) => {            
      //       console.log("succes, get data:", res);


      //       this.confirmLoading = false
      //       this.PageLoading = false


      //       this.ArrCompany.forEach((val: any, k: any) => {
      //         console.log('ArrCompany v : ', val['companyId'])
      //         console.log('ArrCompany v : ', val.companyId)
      //         console.log('ArrCompany k : ', k)
      //         if (this.ValCompany == val.companyId) {
      //           this.ValCompany = this.ArrCompany[k]['companyId']
      //           this.ValCompanyName = this.ArrCompany[k]['companyName']
      //         }

      //       })
      //       console.log('ValCompany', this.ValCompany);
      //       console.log('ValCompanyName', this.ValCompanyName);
      //       localStorage.setItem("COMPANYSELECTID", this.ValCompany)
      //       localStorage.setItem("COMPANYSELECTNAME", this.ValCompanyName)

      //       // saya potong hingga 10 karakter karena jika terlalu panjang akan menambah height member header
      //       this.getcompanyName = this.ValCompanyName

      //       this.notYetSelectCompany = false

      //       if (res.data) {

      //         this.modalService.dismissAll()


      //         localStorage.removeItem("USERCOMPANY")
      //         localStorage.setItem("USERCOMPANY", JSON.stringify(res.data.chooseCompany.staff))
      //         // dipindah kesini agar saat getMenu roleMembership nya bisa ketahuan
      //         // semula this.usercompany selalu null walaupun sudah diambil dari localStorage USERCOMPANY
      //         this.usercompany = res.data.chooseCompany.staff;
      //         this.getMenu()
      //         this.getCurrency()

      //         if (res.data.chooseCompany.staff.roleMembership == 1) {
      //           this.roleName = 'Owner'
      //         }
      //         else if (res.data.chooseCompany.staff.roleMembership == 2) {
      //           this.roleName = 'S. Manager'
      //         }
      //         else if (res.data.chooseCompany.staff.roleMembership == 4) {
      //           this.roleName = 'Manager'
      //         } else {
      //           this.roleName = 'Staff'
      //         }

      //         localStorage.setItem("JWT_TOKEN", res.data.chooseCompany.accessToken)

      //         if (res.data.chooseCompany.isFirstTimeLogin==true && res.data.chooseCompany.staff.roleMembership == 1) {
      //           //redirect to subscription plan / show subcription plan
      //           //this.subscription=true
      //           this.display = "none";
      //           //this.PageLoading=true
      //           //alert('redirect to company settings..');
      //           this.router.navigate(['/setting/company-detail']);
      //           return
      //         }

      //         //this.display = "none";//close modal
      //         this.confirmLoading = true


      //         // //--redirect dashboard--//
      //         // var getusercompany = localStorage.getItem("USERCOMPANY")
      //         // var redirect = '/';
      //         // console.log('redirectDasboard 2 : ', usercompany);
      //         // if (getusercompany !== null) {
      //         //   var usercompany = JSON.parse(getusercompany);
      //         //   redirect = Util.redirectDasboard(usercompany.roleMembership)

      //         //   if(this.router.url == redirect){
      //         //     location.reload() 
      //         //   } else {
      //         //     //this.router.navigate([redirect]);
      //         //     window.location.replace(redirect);
      //         //   }

      //         // } else {
      //         //   //this.router.navigate([redirect]);
      //         //   window.location.replace(redirect);
      //         // }
      //         // return
      //         // //--redirect dashboard--//

      //         if(this.router.url=='/dashboard'){
      //           location.reload() 
      //         } else {
      //           this.router.navigate(['/dashboard']);
      //         }
      //         return
      //         if(this.router.url=='/'){
      //           location.reload() 
      //         } else {
      //           this.router.navigate(['/']);
      //         }
      //         //location.reload()          
              
      //       }
      //     },
      //     error => {
      //       console.log("there was an error sending the query", error);

      //       this.confirmLoading = false
      //       this.errChooseCompany = error
      //       this.display = "none";
      //     }
      //   );



    } else {
      this.emptyCompany = true;
      this.errChooseCompany = ''
    }


  }

  getLogo(){
    this.companyService
      .getCompanyLogo()
      .subscribe((res:any) => {
        console.log('getcompanyLogo', 'res', res)
        if(res.data.getCompanyLogo){
          // console.log('CompanyDetail', 'res', res)
          localStorage.removeItem('COMPANY_LOGO');
          localStorage.setItem('COMPANY_LOGO', res.data.getCompanyLogo);
          this.companyLogo = res.data.getCompanyLogo
        }      
      });
  }

  redirectDashboardUrl(){
    return Util.redirectDasboard(this.roleId) 
  }

  gototop() {
    window.scroll(0, 0)
  }

  currentPosition = window.pageYOffset;
  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    //console.log('event',event);
    this.currentPosition = window.pageYOffset;
    //console.log('currentPosition',this.currentPosition);

    if (window.pageYOffset > 300) {
      this.showtotop = true
    } else {
      this.showtotop = false
    }

  }
  
  switchLang(lang:any) {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.currentlang = lang
    //window.location.reload();
  }


  hideshowChild(id:any){
    if (this.dispaymenu[id] == true) {
      this.dispaymenu[id] = false
    } else {
      this.dispaymenu[id] = true
    }
    console.log('hidechildmenu',this.hidechildmenu);
  }
  arrowmenu(menuid:any,parentmenuid:any){
    if(parentmenuid){
      if(this.dispaymenu[parentmenuid]){
        if (this.hidechildmenu[menuid] == true) {
          this.hidechildmenu[menuid] = false
        } else {
          this.hidechildmenu[menuid] = true
        }
        return ''
      } else {

        return 'd-none'
      }
    } else {
      return ''
    }
  }


  globalSearch(){
    var keyword = encodeURIComponent(this.valSearch)
    //this.router.navigate(['/search/'+keyword]);

    var checkKeyword = this.valSearch.length
    console.log('checkKeyword : ',checkKeyword)
    if(keyword.length<3){
      this.invalidSearch=true
      return
    } else{
      this.invalidSearch=false
    }
    
    window.location.href = '/search/'+keyword;
    //this.router.navigate(['/search/'+keyword])
    // .then(() => {
    //   window.location.reload();
    // });

    // this.router.events
    // .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    // .subscribe((events: RoutesRecognized[]) => {
    //   console.log('previous url', events[0].urlAfterRedirects);
    //   console.log('current url', events[1].urlAfterRedirects);
    //   var prevurl = events[0].urlAfterRedirects
    //   var currurl = events[1].urlAfterRedirects
    //   if(prevurl !== currurl){
    //     console.log('not same, execute reload url');
    //     window.location.reload();
    //   }
    // });

  }

  limitText(val:any){
    var checkChar = val
    if(checkChar.length>11){
      checkChar = checkChar.substring(0,11) + '...'
    }
    return checkChar
  }


  getTotalMessages(){
    this.messagingService.getTotalMessage().subscribe((res:any) => {
      console.log(res)
      if(res.data?.getTotalUnreadMessageThread){ 
        this.totalMessages = res.data?.getTotalUnreadMessageThread.totalUnreadMessage
        this.totalNotification = res.data?.getTotalUnreadMessageThread.totalUnreadNotificationSystem
      }
    });
  }


	open() {

    var modalOption = {}
    if(this.notYetSelectCompany){
      modalOption = { ariaLabelledBy: 'modal-basic-title',centered: true , backdrop: 'static', keyboard: false}
    } else {
      modalOption = { ariaLabelledBy: 'modal-basic-title',centered: true}
    }

		this.modalService.open(this.myModal, modalOption).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);

    this.emptyCompany = false;

	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  getCart(){
    var limit=999
    var offset=0 
    console.log('othersService.. ')
    this.othersService.getCartList(limit,offset).subscribe((res:any) => {
      console.log('totalCart : ',res.data?.getCartList.totalData)
      if(res.data?.getCartList){
        this.totalCart = res.data?.getCartList.totalData
      }
      // else if(res.errors){
      //   var error = res.errors[0].message
      //   console.log('error',error);
      //   var errMsg = error + '';
      //   var result = errMsg.split(":");
      //   var resMsg = result[result.length - 1];
      //   var msg = (resMsg)? resMsg: errMsg
      //   console.log('msg',msg);

      //   var errortext = msg

      //   console.log('res',res.errors[0].extensions.type);
      //   if(res.errors[0].extensions.type){
      //     //get translate error, if exsit, replace errortext
      //     this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
      //       if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
      //         errortext = data
      //       }
      //     });
      //     //force redirect on specific errortype
      //     var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
      //     if(forceRedirect){
      //       window.location.href = forceRedirect
      //       return
      //     }
      //   }
      //   this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      // }
      // else {
      //   this.popupmodal.showFail().subscribe((res:any) => {})
      // }
      
    });

  }
}
