import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { config } from 'src/app/config';
import Util from 'src/app/_helpers/util';

@Component({
  selector: 'app-board-rfq',
  templateUrl: './board-rfq.component.html',
  styleUrls: ['./board-rfq.component.scss']
})
export class BoardRfqComponent implements OnInit {

  @Input() rfq?: any;
  @Input() rfqType?: string='vendor'; //procurement / vendor

  @Input() forceMobile?: boolean=false; // homepage
  config:any
  constructor() { }

  ngOnInit(): void {
    this.config = config
    console.log('rfq board masuk :: ',this.rfq)
    this.rfq.node = this.rfq

    // city
    // country
    // submitQuotationLastDate
    // totalVendorParticipation
    // procurementName
    // rfqState
    // isAlreadyParticipating
    // rfqVendorCategory
    // rfqDisplayId
    // description
    // rfqItem
    // haveMoreRfqItem
    
  }

  divDetailspec(val:any){
    var divdet = Util.formatShortDesc(val)
    if(divdet.length>50){
      divdet = divdet.substring(0, 50) + ' ...'
    }
    return divdet
  }
  divDetailspecMobile(val:any){
    var divdet = Util.formatShortDesc(val)
    if(divdet.length>30){
      divdet = divdet.substring(0, 30) + ' ...'
    }
    return divdet
  }

  formatDisplayId(displayId: any) {
    var re = new RegExp('.{1,4}', 'g');
	  return displayId.match(re).join("-");
  }
  
}
