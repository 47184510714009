export class getIdRfq {
  private static isSearchID(input: string): boolean {
    // Pola regex untuk mendeteksi ID dengan format yang disebutkan
    const regex = /(?:RFQ\s#\s|#\s|)?([a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}(-[a-zA-Z0-9]+)*)(?=\s|$)/;
    return regex.test(input);
  }

  private static cleanSearchID(input: string): string {
    const match = input.match(/(?:RFQ\s#\s|#\s|)?([a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}(-[a-zA-Z0-9]+)*)(?=\s|$)/);
    return match ? match[1].replace(/-/g, "") : "";
  }

  static filter(input: string): string {
    if (this.isSearchID(input)) {
      return this.cleanSearchID(input);
    }
    return input;
  }
}