import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {gql, Apollo} from 'apollo-angular';
import { AuthService } from 'src/app/auth/services/auth.service';
import { config } from './../../../config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { onError } from 'apollo-link-error';
import { LoginService } from 'src/app/_services/login.services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { HttpClient } from '@angular/common/http';



// const mutationForgot = gql`
// mutation forgotPassword($email:EmailAddress!){
//   forgotPassword(input: {
//       userEmail: $email
//   })
// }
// `;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: any = {
    email: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  pageloading: boolean = false;

  tryLogin:number=0
  requiredCapcha:boolean=false
  cpatchaText:string=''
  config:any
  page: string='login';
  
  forgotForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email, Validators.pattern("^(?!.{255})([a-zA-Z0-9.!#\$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)\$")]],
  });
  submitedforgot: boolean=false;
  isForgotFailed:string=''
  ValEmail:string=''

  forgotcompleted:boolean=false
  forgotresstatus:boolean=false
  forgotresmsg:string=''

  showPass:boolean = false
  
  getipdetail : any ='';

  rememberMe:boolean=false

  constructor(
    private apollo: Apollo,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private popupmodal : Popupmodal,
    private http:HttpClient,
    private loginService: LoginService
  ) { }


  ngOnInit(): void {

    var href = this.router.url;
    console.log('this.href : ',href);
    if(href=='/logout'){
      // alert('/logout')
      this.authService.forceLogout();
      this.router.navigate(['/login']);
      return;
    }

    this.config = config
    console.log('this.config.captchaLoadAfter',this.config.captchaLoadAfter);
    this.getIPAddrs();


    if(localStorage.getItem('rememberMe') && localStorage.getItem('rememberEmail')){
      this.form.email=localStorage.getItem('rememberEmail')
      this.rememberMe=true
    }

  }

  getIPAddrs() {
    var urlGetIp = 'http://api.ipify.org/?format=json'
    if (location.protocol == 'https:') {
      urlGetIp = 'https://api.ipify.org/?format=json';
    }
    return this.http.get(urlGetIp).subscribe((res:any)=>{  
      this.getipdetail=res;  
      console.log('getipdetail',this.getipdetail);
    });  
  }

  onSubmit(): void {
    const { email, password } = this.form;
    //const loginIp = '8.8.8.8'
    var loginIp = (this.getipdetail!=='') ? (this.getipdetail.ip)?this.getipdetail.ip: '8.8.8.8' : '0.0.0.0';
    this.pageloading=true;

    // this.authService.login(
    //   {
    //     username: email,
    //     password: password
    //   }
    // )
    // .subscribe(success => {
    //   if (success) {
    //     this.router.navigate(['/dashboard']);
    //   }
    // });

    this.login(email, password, loginIp);
  }

  login(email: string, password: string, loginIp: string) {
    this.tryLogin++
    console.log('this.tryLogin',this.tryLogin);
    
    if(this.tryLogin >= this.config.captchaLoadAfter){
      this.requiredCapcha=true
    }
    this.isLoginFailed = false;
    this.errorMessage = '';
    
    //var loginIp ='182.181.191.101'
    // var userAgent = window.navigator.userAgent;
    var userAgent = typeof window !== 'undefined' && window.navigator ? window.navigator.userAgent : '';


    this.loginService
    .getPostLoginData(email, password,loginIp, userAgent)
    .subscribe((res:any) => {
      if(res.data){
        console.log("succes, get data:", res);
        this.pageloading=false;

        localStorage.setItem('JWT_TOKEN', res.data.login.accessToken);
        localStorage.setItem('REFRESH_TOKEN', res.data.login.refreshToken);
        localStorage.setItem('USERDATA', JSON.stringify(res.data.login.userData));
        localStorage.setItem('COMPANYDATA', JSON.stringify(res.data.login.companyChoices));
        
        console.log("JWT_TOKEN:", res.data.login.accessToken);
        console.log("REFRESH_TOKEN:", res.data.login.refreshToken);
        console.log("USERDATA:", JSON.stringify(res.data.login.userData) );
        console.log("COMPANYDATA:", JSON.stringify(res.data.login.companyChoices) );
               
        //clear utm_campaign after login
        localStorage.removeItem('utm_campaign');


        this.router.navigate(['/dashboard']);

        // // get return url from query parameters or default to home page
        // const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
        // this.router.navigateByUrl(returnUrl);

        
        // var returnUrl = this.route.snapshot.queryParams['returnUrl'];
        // console.log('returnUrl ',returnUrl)

        // get return url from query parameters or default to home page
        var returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
        if(returnUrl){
          localStorage.setItem('returnUrl', returnUrl);
        }

  

        //this.router.navigate(['/']);
      }
      else if(res.errors){
        this.pageloading=false;
        this.isLoginFailed = true;
        //this.errorMessage = error.message ? error.message : 'username or password is incorrect';  
        this.errorMessage = "Wrong email / password"
        this.cpatchaText =''
        
        //console.log('xxxxres',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          if(res.errors[0].extensions.type == 'UserBlockedException'){
            this.errorMessage = "Too many failed attemp, please check your email"
            this.cpatchaText =''
          } 
          // else if (res.errors[0].extensions.type == 'UserBlockedException'){
          //   this.errorMessage = "Wrong email / password"
          //   this.cpatchaText =''
          // } 
          else {
            this.errorMessage = "Wrong email / password"
            this.cpatchaText =''
          }
        } else {
          this.errorMessage = "Wrong email / password"
          this.cpatchaText =''
        }

      } else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

  }

  reloadPage(): void {
    //window.location.reload();
    console.log('reload / redirect after success')
  }
  
  showdiv(page:any){
    this.page = page

    this.submitedforgot = false;
    this.forgotForm?.reset();

  }

  get g() { return this.forgotForm?.controls; }

  submitforgot(){
    this.submitedforgot = true;
    if (this.forgotForm.invalid) {
      console.log('registerForm invalid');
        return;
    }    

    this.pageloading=true;

    this.loginService
    .getForgot(this.ValEmail)
    .subscribe((res:any) => {
      if(res.data){
        console.log("succes, get data:", res);
        this.pageloading=false;
        //sucess
        this.page=''
        this.forgotcompleted=true
        this.forgotresstatus=true
        this.forgotresmsg='Link to recovery password sent to your email.'
        this.pageloading=false;
      }
      else if(res.errors){
        console.log("there was an error sending the query", res.errors);
        //error
        this.page=''
        this.forgotcompleted=true
        this.forgotresstatus=false
        this.forgotresmsg='opps, something wrong'
        this.pageloading=false;
      } else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

    // this.apollo
    //   .mutate({
    //     mutation: mutationForgot,
    //     variables: {email: this.ValEmail}
    //   })
    //   .subscribe(
    //     (res:any) => {
    //       console.log("succes, get data:", res);
    //       this.pageloading=false;
    //       //sucess
    //       this.page=''
    //       this.forgotcompleted=true
    //       this.forgotresstatus=true
    //       this.forgotresmsg='Link to recovery password sent to your email.'
    //       this.pageloading=false;
    //     },
    //     (error) => {
    //       console.log("there was an error sending the query", error);
    //       //error
    //       this.page=''
    //       this.forgotcompleted=true
    //       this.forgotresstatus=false
    //       this.forgotresmsg='opps, something wrong'
    //       this.pageloading=false;
    //     }
    //   );

  }

  showPassword(){
    this.showPass = (this.showPass)? false:true
  }

  changeChecked(value:boolean){
    if(value){
      localStorage.setItem('rememberMe',''+value+'');
      localStorage.setItem('rememberEmail',''+this.form.email+'');
    } else {
      localStorage.removeItem('rememberMe')
      localStorage.removeItem('rememberEmail')
    } 
}
}
