import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { filter, pairwise } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import Util from 'src/app/_helpers/util';

@Component({
  selector: 'app-headernonlogin',
  templateUrl: './headernonlogin.component.html',
  styleUrls: ['./headernonlogin.component.scss']
})
export class HeadernonloginComponent implements OnInit {

  //isLogin:boolean=false
  
  sidebar:boolean=false
  showtotop:boolean=false
  sidebarmenu:any=[]

  currentlang:any

  valSearch:string=''
  dispaymenu: any = []
  hidechildmenu: any = []

  invalidSearch:boolean=false

  public href: string = "";
  isHomePage: boolean=false;

  public getScreenWidth: any;
  isMobile:boolean=false


  isLoggedin:boolean=false

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.currentlang = localStorage.getItem('language');
    console.log('currentlang',this.currentlang);

    // console.log('isLoggedIn',this.authService.getJwtToken())
    // if(this.authService.getJwtToken()){
    //   this.isLogin = true
    // }
    const fullUrl = this.router.url; // Get the full URL
    const urlSegments = fullUrl.split('/').filter(segment => segment); // Split and filter empty segments
    console.log("urlSegments : ",urlSegments)

    if (urlSegments.length > 0) {
      if(urlSegments[0]=="search"){

        var getketword  = this.route.snapshot.paramMap.get('keyword');
        console.log('getketword',getketword);
        const lastUrlSegment = this.router.url.split('?')[0].split('/').pop()
        console.log('>> ',lastUrlSegment)
        if(lastUrlSegment){
          this.valSearch = decodeURIComponent(lastUrlSegment)
        }

      }
    }


    // var getketword  = this.route.snapshot.paramMap.get('keyword');
    // console.log('getketword',getketword);
    // const lastUrlSegment = this.router.url.split('?')[0].split('/').pop()
    // console.log('>> ',lastUrlSegment)
    // if(lastUrlSegment){
    //   this.valSearch = decodeURIComponent(lastUrlSegment)
    // }

    // this.router.events
    // .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    // .subscribe((events: RoutesRecognized[]) => {
    //   console.log('previous url', events[0].urlAfterRedirects);
    //   console.log('current url', events[1].urlAfterRedirects);
    //   var prevurl = events[0].urlAfterRedirects
    //   var currurl = events[1].urlAfterRedirects
    //   if(prevurl !== currurl){
    //     console.log('not same, execute reload url');
    //     window.location.reload();
    //   }
    // });

    

    this.sidebarmenu = [
      // {
      //   'id': 1,
      //   'icon': '/assets/images/icons/lucide_layout-dashboard.png',
      //   'url': '/dashboard',
      //   'text': 'Dashboard',
      //   'parent': 0
      // },
      {
        'id': 2,
        'icon': '/assets/images/icons/mdi_shopping-outline.png',
        'url': '/vendor/product-list',
        'text': 'Products',
        'parent': 0
      },
      {
        'id': 3,
        'icon': '/assets/images/icons/mdi_form-select.png',
        'url': '',
        'text': 'Procurement Modules',
        'parent': 0
      },
          {
            'id': 5,
            'icon': '/assets/images/icons/uil_server.png',
            'url': '/procurement/rfq-list',
            'text': 'My RFQ',
            'parent': 3
          },
          {
            'id': 6,
            'icon': '/assets/images/icons/ic_outline-request-quote.png',
            'url': '/procurement/rfq/my-proforma-po',
            'text': 'My Proforma PO',
            'parent': 3
          },
          {
            'id': 7,
            'icon': '/assets/images/icons/reccuring.jpg',
            'url': '/procurement/rfq/recurring',
            'text': 'Recurring RFQ',
            'parent': 3
          },
          {
            'id': 8,
            'icon': '/assets/images/icons/history.jpg',
            'url': '/procurement/rfq-history',
            'text': 'RFQ History',
            'parent': 3
          },
          {
            'id': 12,
            'icon': '/assets/images/icons/iconoir_page-star.png',
            'url': '/procurement/rating/procurement-rating-list',
            'text': 'Rating & Review',
            'parent': 3
          },
      {
        'id': 4,
        'icon': '/assets/images/icons/mdi_form-select.png',
        'url': '',
        'text': 'Vendor Modules',
        'parent': 0
      },
          {
            'id': 9,
            'icon': '/assets/images/icons/uil_server.png',
            'url': '/vendor/rfq-board',
            'text': 'RFQ Board',
            'parent': 4
          },
          {
            'id': 10,
            'icon': '/assets/images/icons/ic_outline-request-quote.png',
            'url': '/vendor/rfq-participating-in-list',
            'text': 'Participating In',
            'parent': 4
          },
          {
            'id': 11,
            'icon': '/assets/images/icons/history.jpg',
            'url': '/vendor/rfq-participating-in/history',
            'text': 'History',
            'parent': 4
          },
          {
            'id': 14,
            'icon': '/assets/images/icons/iconoir_page-star.png',
            'url': '/vendor/rating/rating-list',
            'text': 'Rating & Review',
            'parent': 4
          },
      {
        'id': 13,
        'icon': '/assets/images/icons/uil_setting.png',
        'url': '/setting/company-management',
        'text': 'Company Management',
        'parent': 0
      },


    ]
    
    // this.sidebarmenu =[
    //   {
    //     'id':0,
    //     'icon':'/assets/images/icons/lucide_layout-dashboard.png',
    //     'url':'/vendor/dashboard',
    //     'text':'Dashboard'
    //   },
    //   {
    //     'id':1,
    //     'icon':'/assets/images/icons/mdi_shopping-outline.png',
    //     'url':'/vendor/product-list',
    //     'text':'Products'
    //   },
    //   {
    //     'id':2,
    //     'icon':'/assets/images/icons/mdi_form-select.png',
    //     'url':'/procurement/rfq-list',
    //     'text':'RFQ'
    //   },
    //   {
    //     'id':3,
    //     'icon':'/assets/images/icons/ic_outline-note-alt.png',
    //     'url':'/vendor/quotation',
    //     'text':'Quotation'
    //   },
    //   {
    //     'id':4,
    //     'icon':'/assets/images/icons/ic_outline-request-quote.png',
    //     'url':'/vendor/proforma',
    //     'text':'Proforma PO'
    //   },
    //   {
    //     'id':5,
    //     'icon':'/assets/images/icons/uil_setting.png',
    //     'url':'/vendor/setting',
    //     'text':'Settings'
    //   },
    // ]


    this.href = this.router.url;
    console.log('this.href : ',this.href);

    if(this.href=='/'){
      this.isHomePage=true
    }

    this.getScreenWidth = window.innerWidth;
    if(this.getScreenWidth<575){
      this.isMobile=true
    } else {
      this.isMobile=false
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    if(this.getScreenWidth<575){
      this.isMobile=true
    } else {
      this.isMobile=false
    }
  }

  fullMenu(){
    const modalRef = this.modalService.open(ModalComponent, {
      modalDialogClass: 'modal-dialog-centered modal-fullscreen',
      centered: true,
      backdrop : 'static',
      keyboard : false,
      size: 'sm'
    })
    modalRef.componentInstance.modalMenu = 'menu'
    modalRef.componentInstance.modalisLogin = false

  }


  hideshowmenu(){
    if(!this.sidebar){
      this.sidebar=true
    } else {
      this.sidebar=false
    }
  }

  gototop() {
    window.scroll(0,0)
  }

  switchLang(lang:any) {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.currentlang = lang
    //window.location.reload();
  }

  globalSearch(){
    var keyword = encodeURIComponent(this.valSearch)

    var checkKeyword = this.valSearch.length
    console.log('checkKeyword : ',checkKeyword)
    if(keyword.length<3){
      this.invalidSearch=true
      return
    } else{
      this.invalidSearch=false
    }

    //this.router.navigate(['/search/'+keyword]);
    window.location.href = '/search/'+keyword;
    //this.router.navigate(['/search/'+keyword])
    // .then(() => {
    //   window.location.reload();
    // });

    // this.router.events
    // .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    // .subscribe((events: RoutesRecognized[]) => {
    //   console.log('previous url', events[0].urlAfterRedirects);
    //   console.log('current url', events[1].urlAfterRedirects);
    //   var prevurl = events[0].urlAfterRedirects
    //   var currurl = events[1].urlAfterRedirects
    //   if(prevurl !== currurl){
    //     console.log('not same, execute reload url');
    //     window.location.reload();
    //   }
    // });

  }
  
  hideshowChild(id:any){
    if (this.dispaymenu[id] == true) {
      this.dispaymenu[id] = false
    } else {
      this.dispaymenu[id] = true
    }
    console.log('hidechildmenu',this.hidechildmenu);
  }
  arrowmenu(menuid:any,parentmenuid:any){
    if(parentmenuid){
      if(this.dispaymenu[parentmenuid]){
        if (this.hidechildmenu[menuid] == true) {
          this.hidechildmenu[menuid] = false
        } else {
          this.hidechildmenu[menuid] = true
        }
        return ''
      } else {

        return 'd-none'
      }
    } else {
      return ''
    }
  }


  
  redirectDashboardUrl(){
    var getrole = ''
    return Util.redirectDasboard(getrole) 
  }
}
