import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { MemberGuard } from './auth/guards/member.guard';
import { LayoutComponent } from './views/includes/layout/layout.component';
import { MemberlayoutComponent } from './views/includes/memberlayout/memberlayout.component';
import { HomeComponent } from './views/pages/home/home.component';
import { LoginComponent } from './views/pages/login/login.component';
import { DashboardComponent } from './views/pages/member/dashboard/dashboard.component';
import { ProductsCompareComponent } from './views/pages/member/products-compare/products-compare.component';
import { ProductsDetailComponent } from './views/pages/member/products-detail/products-detail.component';
import { ProductsPreviewComponent } from './views/pages/member/products-preview/products-preview.component';
// import { ProductsComponent } from './views/pages/member/products/products.component';
import { RegisterComponent } from './views/pages/register/register.component';
// import { SearchComponent } from './views/pages/search/search.component';
import { VerifyComponent } from './views/pages/verify/verify.component';
// import { ProcurementProductsComponent } from './views/pages/procurement/products/products.component';
// import { ProcurementProductsDetailComponent } from './views/pages/procurement/products-detail/products-detail.component';
import { CartComponent } from './views/pages/procurement/cart/cart.component';
import { NotfoundComponent } from './views/pages/notfound/notfound.component';
// import { RfqComponent } from './views/pages/procurement/rfq/rfq.component';
// import { RfqHistoryComponent } from './views/pages/procurement/rfq-history/rfq-history.component';
// import { RfqListComponent } from './views/pages/procurement/rfq-list/rfq-list.component';
// import { VendorRfqListComponent } from './views/pages/member/rfq-list/rfq-list.component';
// import { VendorRfqPreviewComponent } from './views/pages/member/rfq-preview/rfq-preview.component';
// import { VendorRfqParticipatingInListComponent } from './views/pages/member/rfq-participating-in-list/rfq-participating-in-list.component';
// import { VendorRfqComponent } from './views/pages/member/rfq/rfq.component';
// import { VendorRfqParticipatingInPreviewComponent } from './views/pages/member/rfq-participating-in-preview/rfq-participating-in-preview.component';
// import { RfqPreviewComponent } from './views/pages/procurement/rfq-preview/rfq-preview.component';
// import { RfqQuotedComponent } from './views/pages/procurement/rfq-quoted/rfq-quoted.component';
// import { RfqQuotedVendorComponent } from './views/pages/procurement/rfq-quoted-vendor/rfq-quoted-vendor.component';
// import { RfqQuotedPreviewComponent } from './views/pages/procurement/rfq-quoted-preview/rfq-quoted-preview.component';
// import { RfqQuotationComponent } from './views/pages/member/rfq-quotation/rfq-quotation.component';
// import { RfqQuotationCompareComponent } from './views/pages/member/rfq-quotation-compare/rfq-quotation-compare.component';
// import { RfqHistoryDetailComponent } from './views/pages/procurement/rfq-history-detail/rfq-history-detail.component';
// import { ProformaPoComponent } from './views/pages/procurement/proforma-po/proforma-po.component';
// import { ProformaPoDetailComponent } from './views/pages/procurement/proforma-po-detail/proforma-po-detail.component';
// import { RfqPreviewQuoteComponent } from './views/pages/member/rfq-preview-quote/rfq-preview-quote.component';
// import { RfqQuotationHistoryDetailComponent } from './views/pages/member/rfq-quotation-history-detail/rfq-quotation-history-detail.component';
// import { RfqQuotationHistoryComponent } from './views/pages/member/rfq-quotation-history/rfq-quotation-history.component';
// import { RfqDraftViewComponent } from './views/pages/procurement/rfq-draft-view/rfq-draft-view.component';
// import { RfqQuotationDraftViewComponent } from './views/pages/member/rfq-quotation-draft-view/rfq-quotation-draft-view.component';
// import { CompanyManagementComponent } from './views/pages/setting/company-management/company-management.component';
// import { CompanyDetailComponent } from './views/pages/setting/company-detail/company-detail.component';
// import { UserManagementComponent } from './views/pages/setting/user-management/user-management.component';
// import { UserDetailComponent } from './views/pages/setting/user-detail/user-detail.component';
import { VerifystaffComponent } from './views/pages/verifystaff/verifystaff.component';
import { ResetpasswordComponent } from './views/pages/resetpassword/resetpassword.component';
import { UnblockemailComponent } from './views/pages/unblockemail/unblockemail.component';
import { ProfileComponent } from './views/pages/profile/profile.component';
import { ProductComponent } from './views/pages/search/product/product.component';
import { ProductDetailComponent } from './views/pages/search/product-detail/product-detail.component';
// import { ReccurringComponent } from './views/pages/procurement/reccurring/reccurring.component';
// import { ProformaPoSummaryComponent } from './views/pages/procurement/proforma-po-summary/proforma-po-summary.component';
import { MessagingComponent } from './views/pages/messaging/messaging.component';
import { VendorDetailComponent } from './views/pages/search/vendor-detail/vendor-detail.component';
// import { ProcurementRatingListComponent } from './views/pages/rating/procurement-rating-list/procurement-rating-list.component';
// import { ProcurementRatingVendorComponent } from './views/pages/rating/procurement-rating-vendor/procurement-rating-vendor.component';
// import { ProcurementRatingProductComponent } from './views/pages/rating/procurement-rating-product/procurement-rating-product.component';
// import { RfqPoDetailComponent } from './views/pages/member/rfq-po-detail/rfq-po-detail.component';
import { RfqPoExportComponent } from './views/pages/member/rfq-po-export/rfq-po-export.component';
// import { VendorRatingListComponent } from './views/pages/rating/vendor-rating-list/vendor-rating-list.component';
// import { VendorRatingProcurementComponent } from './views/pages/rating/vendor-rating-procurement/vendor-rating-procurement.component';
import { FeedbackreportComponent } from './views/pages/feedbackreport/feedbackreport.component';
import { HomepageComponent } from './views/pages/homepage/homepage.component';
import { HelpComponent } from './views/pages/help/help.component';
import { ThankyouComponent } from './views/pages/thank-you/thank-you.component';
import { SubscriptionComponent } from './views/pages/subscription/subscription.component';
import { ContactusComponent } from './views/pages/contact-us/contact-us.component';
import { LandingShareComponent } from './views/pages/landing-share/landing-share.component';
import { LandingpageComponent } from './views/pages/landing-page/landingpage.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: HomeComponent,
  //   canLoad: [AuthGuard]
  // },
  {
    path: '',
    component: LandingpageComponent, // HomepageComponent,
    data: {
      title: 'Aplikasi dengan cara yang lebih baik untuk menemukan dan menghubungi Procurement dan Vendor | CepetDapet'
    }
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        data: {
          title: 'Dashboard'
        }
      },
      {
        path: 'profile',
        component: ProfileComponent,
        // canActivate: [MemberGuard],
        // canLoad: [MemberGuard],
        data: {
          title: 'Profile'
        },
        
      },
      {
        path: 's/:RfqId',
        component: LandingShareComponent,
        data: {
          title: 'Share RFQ',
          image: 'https://cepetdapet.com/assets/Share_RFQ.jpg',
          ogImage:'https://cepetdapet.com/assets/Share_RFQ.jpg',
          description: 'Aplikasi dengan cara yang lebih baik untuk menemukan dan menghubungi Procurement dan Vendor. Kami bukan market place dan tidak menarik komisi.'
        }
      },
      {
        path: 'search/:keyword',
        component: ProductComponent,
        data: {
          title: 'Search'
        }
      },
      {
        path: 'search-rfq/:keywordRfq',
        component: ProductComponent,
        data: {
          title: 'Search RFQ'
        }
      },
      {
        path: 'product/:companydomain/:keyword',
        component: ProductDetailComponent,
        data: {
          title: 'Product'
        }
      },
      {
        path: 'product/view/:getcompanyId/:keyword',
        component: ProductDetailComponent,
        data: {
          title: 'Product'
        }
      },
      {
        path: 'cart',
        component: CartComponent,
        data: {
          title: 'Cart'
        }
      },

      {
        path: 'messaging',
        component: MessagingComponent,
        // canActivate: [MemberGuard],
        // canLoad: [MemberGuard],
        data: {
          title: 'Messaging'
        },
        canActivate: [MemberGuard],
        canLoad: [MemberGuard]
      },
      {
        path: 'vendor-detail/:VendorDomain',
        component: VendorDetailComponent,
        data: {
          title: 'Vendor Detail'
        }
      },
      {
        path: 'help',
        component: HelpComponent,
        data: {
          title: 'Help'
        }
      },
      {
        path: 'subscription',
        component: SubscriptionComponent,
        data: {
          title: 'Subscription'
        }
      },
      {
        path: 'contact-us',
        component: ContactusComponent,
        data: {
          title: 'Contact Us'
        }
      },

      // {
      //   path: 'help/:url1',
      //   component: HelpComponent,
      //   data: {
      //     title: 'Help Detail'
      //   }
      // },
      {
        path: 'help',
        component: HelpComponent,
        data: {
          title: 'Help'
        },
        children: [
          {
            path: '**',
            component: HelpComponent,
            data: {
              title: 'Help Detail'
            }
          },
        ]
      },

      // {
      //   path: 'help/:url1',
      //   component: HelpComponent,
      //   data: {
      //     title: 'Help Detail'
      //   }
      // },
      // {
      //   path: 'help/:url1/:url2',
      //   component: HelpComponent,
      //   data: {
      //     title: 'Help Detail'
      //   }
      // },
      // {
      //   path: 'help/:url1/:url2/:url3',
      //   component: HelpComponent,
      //   data: {
      //     title: 'Help Detail'
      //   }
      // },
      // {
      //   path: 'help/:url1/:url2/:url3/:url4',
      //   component: HelpComponent,
      //   data: {
      //     title: 'Help Detail'
      //   }
      // },

      // {
      //   path: 'login',
      //   component:LoginComponent,
      //   data: {
      //     title: 'Login'
      //   },
      //   canActivate: [AuthGuard]
      // },
      // {
      //   path:'regis',
      //   data: {
      //     title: 'Regis'
      //   },
      //   component:RegisterComponent
      // },
    ]
  },
  // {
  //   path: 'landing-page',
  //   component: LandingpageComponent,
  //   data: {
  //     title: 'Aplikasi dengan cara yang lebih baik untuk menemukan dan menghubungi Procurement dan Vendor | CepetDapet'
  //   }
  // },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'logout',
    component: LoginComponent,
    data: {
      title: 'Logout'
    }
  },
  {
    path: 'regis',
    component: RegisterComponent,
    data: {
      title: 'Regis'
    },
  },
  {
    path: 'feedback-report',
    component: FeedbackreportComponent,
    data: {
      title: 'Feedback Report'
    }
  },
  {
    path: 'po/export/pdf/:PoId',
    component: RfqPoExportComponent,
    data: {
      title: 'PO Export PDF'
    },
    canActivate: [MemberGuard],
    canLoad: [MemberGuard]
  },

  // {
  //   path: 'email-verification',
  //   redirectTo: ''
  // },
  {
    path: 'email-verification/:token',
    data: {
      title: 'Email Verification'
    },
    component: VerifyComponent
  },
  {
    path: 'email-verification-user/:token',
    data: {
      title: 'Email Verification User'
    },
    component: VerifystaffComponent
  },
  {
    path: 'success-verification',
    data: {
      title: 'Success Verification'
    },
    component: ThankyouComponent
  },
  {
    path: 'email-unblock/:token',
    data: {
      title: 'Email Unblock'
    },
    component: UnblockemailComponent
  },
  {
    path: 'reset-password/:token',
    data: {
      title: 'Reset Password'
    },
    component: ResetpasswordComponent
  },
  {
    path: 'tos-applied',
    component: MemberlayoutComponent,
    canActivate: [MemberGuard],
    canLoad: [MemberGuard],
    data: {
      title: 'TOS Applied'
    }
  },
  {
    path: 'dashboard',
    component:MemberlayoutComponent,
    canActivate: [MemberGuard],
    canLoad: [MemberGuard],
    data: {
      title: 'dashboard'
    },
    children: [
      {
        path: '',
        component: DashboardComponent,
        data: {
          title: 'Dashboard'
        }
      },
    ]
  },
  // {
  //   path: 'search',
  //   component: SearchComponent,
  //   canActivate: [MemberGuard],
  //   canLoad: [MemberGuard]
  // },
  {
    path: 'member/products',
    data: {
      title: 'Products'
    },
    component: ProductsCompareComponent,
    // canActivate: [MemberGuard],
    // canLoad: [MemberGuard]
  },
  {
    path: 'member/products-detail',
    data: {
      title: 'Products Detail'
    },
    component: ProductsDetailComponent,
    // canActivate: [MemberGuard],
    // canLoad: [MemberGuard]
  },
  {
    path: 'member/products-details',
    data: {
      title: 'Products Preview'
    },
    component: ProductsPreviewComponent,
    // canActivate: [MemberGuard],
    // canLoad: [MemberGuard]
  },
  {
    path: 'member/products-detail/:ProductId',
    component: ProductsPreviewComponent,
    data: {
      title: 'View Product'
    }
  },
  // {
  //   path: 'setting',
  //   component: MemberlayoutComponent,
  //   data: {
  //     title: 'setting'
  //   },
  //   children: [
  //     {
  //       path: 'company-management',
  //       component: CompanyManagementComponent,
  //       data: {
  //         title: 'Company Management'
  //       }
  //     },
  //     {
  //       path: 'company-detail',
  //       component: CompanyDetailComponent,
  //       data: {
  //         title: 'Company Detail'
  //       }
  //     },
  //     {
  //       path: 'staff-management',
  //       component: UserManagementComponent,
  //       data: {
  //         title: 'Staff Management'
  //       }
  //     },
  //     {
  //       path: 'staff-detail/add',
  //       component: UserDetailComponent,
  //       data: {
  //         title: 'Add Staff Detail'
  //       }
  //     },    
  //     {
  //       path: 'staff-detail/:action/:verify/:id',
  //       component: UserDetailComponent,
  //       data: {
  //         title: 'View Staff Management'
  //       }
  //     },
  //   ],
  //   canActivate: [MemberGuard],
  //   canLoad: [MemberGuard]
  // }, 
  {
    path: 'setting',
    data: {
      title: 'Setting'
    },
    loadChildren: () => import('./views/pages/setting/setting.module').then(m => m.SettingModule),
    // canActivate: [AuthGuard]
  },  
  // {
  //   path: 'rating',
  //   component: MemberlayoutComponent,
  //   data: {
  //     title: 'rating'
  //   },
  //   children: [
  //     {
  //       path: 'procurement-rating-list',
  //       component: ProcurementRatingListComponent,
  //       data: {
  //         title: 'Procurement Rating List'
  //       }
  //     },
  //     {
  //       path: 'procurement-rating-vendor/:proformaPoId',
  //       component: ProcurementRatingVendorComponent,
  //       data: {
  //         title: 'Procurement Rating Vendor'
  //       }
  //     },
  //     {
  //       path: 'procurement-rating-products',
  //       component: ProcurementRatingProductComponent,
  //       data: {
  //         title: 'Procurement Rating Products'
  //       }
  //     },
  //   ],
  //   canActivate: [MemberGuard],
  //   canLoad: [MemberGuard]
  // }, 
  // {
  //   path: 'product/add',
  //   component: ProductsDetailComponent,
  //   data: {
  //     title: 'Add Product'
  //   }
  // },
  // {
  //   path: 'vendor',
  //   component: MemberlayoutComponent,
  //   data: {
  //     title: 'vendor'
  //   },
  //   children: [
  //     // {
  //     //   path: 'dashboard',
  //     //   component: DashboardComponent,
  //     //   data: {
  //     //     title: 'Dashboard'
  //     //   }
  //     // },
  //     {
  //       path: 'product-list',
  //       component: ProductsComponent,
  //       data: {
  //         title: 'Product List'
  //       }
  //     },
  //     {
  //       path: 'product/add',
  //       component: ProductsDetailComponent,
  //       data: {
  //         title: 'Add Product'
  //       }
  //     },
  //     {
  //       path: 'product/edit/:ProductId',
  //       component: ProductsDetailComponent,
  //       data: {
  //         title: 'Edit Product'
  //       }
  //     },
  //     {
  //       path: 'product/detail/:ProductId',
  //       component: ProductsPreviewComponent,
  //       data: {
  //         title: 'View Product'
  //       }
  //     },
  //     {
  //       path: 'product/compare/:ProductId',
  //       component: ProductsCompareComponent,
  //       data: {
  //         title: 'Compare Product'
  //       }
  //     },
  //     {
  //       path: 'rfq-board',
  //       component: VendorRfqListComponent,
  //       data: {
  //         title: 'RFQ Board'
  //       }
  //     },
  //     {
  //       path: 'rfq-not-participating-in/detail/:RfqId',
  //       component: VendorRfqPreviewComponent,
  //       data: {
  //         title: 'View RFQ'
  //       }
  //     },
  //     // {
  //     //   path: 'rfq/detail-quote/:RfqId/:QuotationId',
  //     //   component: RfqPreviewQuoteComponent,
  //     //   data: {
  //     //     title: 'View RFQ Quote'
  //     //   }
  //     // },
      
  //     {
  //       path: 'rfq-participating-in-list',
  //       component: VendorRfqParticipatingInListComponent,
  //       data: {
  //         title: 'RFQ Participating In'
  //       }
  //     },
  //     {
  //       path: 'rfq-participating-in/detail/:RfqId/:QuotationId',
  //       component: VendorRfqParticipatingInPreviewComponent,
  //       data: {
  //         title: 'Quoted RFQ Participating In'
  //       }
  //     },
  //     {
  //       path: 'rfq/:RfqId/:QuotationId',
  //       component: VendorRfqComponent,
  //       data: {
  //         title: 'Vendor RFQ'
  //       }
  //     },
  //     {
  //       path: 'rfq-participating-in/quotation-detail/:RfqId/:QuotationId',
  //       component: RfqQuotationComponent,
  //       data: {
  //         title: 'Vendor Quotation RFQ'
  //       }
  //     },
  //     {
  //       path: 'rfq-participating-in/compare-quotation/:RfqId/:QuotationId',
  //       component: RfqQuotationCompareComponent,
  //       data: {
  //         title: 'Vendor Compare Quotation RFQ'
  //       }
  //     },
  //     {
  //       path: 'rfq-participating-in/history',
  //       component: RfqQuotationHistoryComponent,
  //       data: {
  //         title: 'RFQ History'
  //       }
  //     },
  //     {
  //       path: 'rfq-participating-in/history/detail/:RfqId/:QuotationId',
  //       component: RfqQuotationHistoryDetailComponent,
  //       data: {
  //         title: 'RFQ History Detail'
  //       }
  //     },  
  //     {
  //       path: 'rfq/draft-mode',
  //       component: RfqQuotationDraftViewComponent,
  //       data: {
  //         title: 'RFQ PO Draft Detail'
  //       }
  //     },
  //     {
  //       path: 'rfq-participating-in/po/:PoId', // vendor / procurement url
  //       component: RfqPoDetailComponent,
  //       data: {
  //         title: 'PO Detail'
  //       }
  //     },  
  //     {
  //       path: 'rfq-participating-in/po/export/:PoId', // vendor / procurement url
  //       component: RfqPoExportComponent,
  //       data: {
  //         title: 'Export PO Detail'
  //       }
  //     },
  //     {
  //       path: 'rating',
  //       data: {
  //         title: 'rating'
  //       },
  //       children: [
  //         {
  //           path: 'rating-list',
  //           component: VendorRatingListComponent,
  //           data: {
  //             title: 'Vendor Rating List'
  //           }
  //         },
  //         {
  //           path: 'vendor-rating-procurement/:proformaPoId',
  //           component: VendorRatingProcurementComponent,
  //           data: {
  //             title: 'Vendor Rating Procurement'
  //           }
  //         },
  //         // {
  //         //   path: 'procurement-rating-products',
  //         //   component: ProcurementRatingProductComponent,
  //         //   data: {
  //         //     title: 'Procurement Rating Products'
  //         //   }
  //         // },
  //       ],
  //       canActivate: [MemberGuard],
  //       canLoad: [MemberGuard]
  //     },   
  //   ],
  //   canActivate: [MemberGuard],
  //   canLoad: [MemberGuard]
  // },
  {
    path: 'vendor',
    data: {
      title: 'Vendor'
    },
    loadChildren: () => import('./views/pages/member/member.module').then(m => m.MemberModule),
    // canActivate: [AuthGuard],
    // canLoad: [MemberGuard]
  }, 
  // {
  //   path: 'procurement',
  //   component: MemberlayoutComponent,
  //   data: {
  //     title: 'procurement'
  //   },
  //   children: [
  //     // {
  //     //   path: 'dashboard',
  //     //   component:DashboardComponent,
  //     //   data: {
  //     //     title: 'Dashboard'
  //     //   }
  //     // },
  //     {
  //       path: 'rfq-list',
  //       component: RfqListComponent,
  //       data: {
  //         title: 'RFQ List'
  //       }
  //     },
  //     {
  //       path: 'rfq',
  //       component: RfqComponent,
  //       data: {
  //         title: 'New RFQ'
  //       }
  //     },
  //     {
  //       path: 'rfq-history',
  //       component: RfqHistoryComponent,
  //       data: {
  //         title: 'RFQ History'
  //       }
  //     },
  //     {
  //       path: 'rfq-history-detail/:RfqId',
  //       component: RfqHistoryDetailComponent,
  //       data: {
  //         title: 'RFQ History Detail'
  //       }
  //     },
  //     {
  //       path: 'products',
  //       component: ProcurementProductsComponent,
  //       data: {
  //         title: 'Products'
  //       }
  //     },
      // {
      //   path: 'cart',
      //   component: CartComponent,
      //   data: {
      //     title: 'Cart'
      //   }
      // },
  //     {
  //       path: 'rfq/edit/:RfqId',
  //       component: RfqComponent,
  //       data: {
  //         title: 'Edit RFQ'
  //       }
  //     },
  //     {
  //       path: 'rfq/detail/:RfqId',
  //       component: RfqPreviewComponent,
  //       data: {
  //         title: 'View Detail RFQ'
  //       }
  //     },
  //     {
  //       path: 'rfq/draft-mode',
  //       component: RfqDraftViewComponent,
  //       data: {
  //         title: 'Proforma Draft PO Detail'
  //       }
  //     },
  //     {
  //       path: 'rfq/quoted/edit/:RfqId',
  //       component: RfqQuotedComponent,
  //       data: {
  //         title: 'New RFQ'
  //       }
  //     },
  //     {
  //       path: 'rfq/quoted/detail/:RfqId',
  //       component: RfqQuotedPreviewComponent,
  //       data: {
  //         title: 'Preiew Detail RFQ'
  //       }
  //     },
  //     {
  //       path: 'rfq/quoted/vendor/:RfqId/:quotationId',
  //       component: RfqQuotedVendorComponent,
  //       data: {
  //         title: 'View Detail RFQ by Vendor'
  //       }
  //     },
  //     {
  //       path: 'rfq/proforma-po/:RfqId',
  //       component: ProformaPoComponent,
  //       data: {
  //         title: 'Proforma PO'
  //       }
  //     },
  //     // {
  //     //   path: 'rfq/proforma-po-detail/:RfqId/:PoId',
  //     //   component: ProformaPoDetailComponent,
  //     //   data: {
  //     //     title: 'Proforma PO Detail'
  //     //   }
  //     // }, 
  //     {
  //       path: 'rfq/proforma-po-detail/:PoId', // vendor / procurement url
  //       component: RfqPoDetailComponent,
  //       data: {
  //         title: 'Proforma PO Detail'
  //       }
  //     },    
  //     {
  //       path: 'rfq/recurring',
  //       component: ReccurringComponent,
  //       data: {
  //         title: 'Recurring RFQ'
  //       }
  //     },   
  //     {
  //       path: 'rfq/recurring/detail/:RfqId',
  //       component: ReccurringComponent,
  //       data: {
  //         title: 'Recurring RFQ Detail'
  //       }
  //     },   
  //     {
  //       path: 'rfq/my-proforma-po',
  //       component: ProformaPoSummaryComponent,
  //       data: {
  //         title: 'Proforma PO'
  //       }
  //     },      

      
  //   ],
  //   canActivate: [MemberGuard],
  //   canLoad: [MemberGuard]
  // },
  {
    path: 'procurement',
    data: {
      title: 'Procurement'
    },
    loadChildren: () => import('./views/pages/procurement/procurement.module').then(m => m.ProcurementModule),
    // canActivate: [AuthGuard],
    // canLoad: [MemberGuard]
  }, 
  // {
  //   path: 'subscription',
  //   component: SubscriptionComponent
  // },
  //Wild Card Route for 404 request
  {
    path: '**', pathMatch: 'full',
    data: {
      title: 'Not found'
    },
    component: NotfoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
