import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {gql, Apollo} from 'apollo-angular';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ProductService } from 'src/app/_services/product.services';
import { Meta, Title } from '@angular/platform-browser';
import { OthersService } from 'src/app/_services/others.service';
import { getIdRfq } from 'src/app/_helpers/getIdRfq';




const querycompanyProducts = gql`
query companyProducts($first: Int, $after: String) {
  companyProducts(input: {first: $first, after: $after}) {
        pageInfo{
            endCursor
            hasNextPage
        }
        edges{
            cursor
            node{
                id
                name
                brand
                description
                sku
                manufacturerSku
                price
                imageUrl
                tags{
                    id
                    tag
                }
                isDisplayPriceOnShopfront
                isDisplayProductOnShopfront
                quotationInitialPriceOffer
                autobidMinimumPrice
                autobidPriceDecrement
                isAutobidEnabled

                state    
                publishApprovalStatus
                publishedOngoingApprovalStatus
                isActivatedByUser
                createdDate
                createdBy
                modifiedDate
                modifiedBy
            }
        }
  }
}
`;


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  keyword:string=''
  prevkeyword:string=''
  offset:number=0
  currentpage:number=1
  totalpage:number =0
  totaldata:number=0
  pagingloading:boolean=false

  BulkLabel:string="Bulk Action"
  first : number = 20
  ValPaging:any = 20
  PageLoading:boolean=false
  loadingNext:boolean=false
  dataProducts: any
  AlldataProducts : any =[]
  endCursor: any
  hasNextPage: any

  hasPrevPage:any
  arrPrevCursor:any=[]
  
  deleteID : any = []
  deleteName : any = []

  LastResult:boolean=false
  isLogin:boolean=false

  isMobile:boolean=false
  innerWidth: number=0;

  dummydata:any = {
    "companyProducts": {
        "pageInfo": {
            "endCursor": "01F4Y34BCH71WGPVCA7YXMQK7D",
            "hasNextPage": true
        },
        "edges": [
            {
                "cursor": "01F4TX62E35S0DB9FBZ39E9F3J",
                "node": {
                    "id": "01F4TX62E35S0DB9FBZ39E9F3J",
                    "name": "name 1",
                    "brand": "brand 1",
                    "description": "desc 1",
                    "sku": "sku 1",
                    "manufacturerSku": "man sku 1",
                    "price": 10000.0,
                    "imageUrl": [
                        "https://img1.jpg",
                        "https://img2.jpg",
                        "https://img3.jpg"
                    ],
                    "tags": [
                        {
                            "id": "01F4GTRNRW79DF4PG27K00XST7",
                            "tag": "minuman"
                        },
                        {
                            "id": "01F4VEJVV73AMH9R5D5A5T9GAR",
                            "tag": "buah"
                        }
                    ],
                    "isDisplayPriceOnShopfront": true,
                    "isDisplayProductOnShopfront": true,
                    "quotationInitialPriceOffer": null,
                    "autobidMinimumPrice": null,
                    "autobidPriceDecrement": null,
                    "isAutobidEnabled": false,
                    "state": "UNPUBLISHED",
                    "publishApprovalStatus": 4,
                    "publishedOngoingApprovalStatus": null,
                    "isActivatedByUser": true,
                    "createdDate": "2021-05-04T05:21:33.634996Z",
                    "createdBy": "SYSTEM",
                    "modifiedDate": "2021-05-10T12:41:38.354336Z",
                    "modifiedBy": "01F4GV0RRVFG2G9GMKFBV6F5ZY"
                }
            },
            {
                "cursor": "01F4TX8YDCDW059XX5VGJ6NBH1",
                "node": {
                    "id": "01F4TX8YDCDW059XX5VGJ6NBH1",
                    "name": "name 2",
                    "brand": "brand 2",
                    "description": "desc 2",
                    "sku": null,
                    "manufacturerSku": null,
                    "price": 20000.0,
                    "imageUrl": [
                        "https://img1.jpg",
                        "https://img2.jpg",
                        "https://img4.jpg"
                    ],
                    "tags": [
                        {
                            "id": "01F4GTRENCD1F6JSYFS1S4ENT2",
                            "tag": "makanan"
                        },
                        {
                            "id": "01F4VEJVV73AMH9R5D5A5T9GAR",
                            "tag": "buah"
                        }
                    ],
                    "isDisplayPriceOnShopfront": false,
                    "isDisplayProductOnShopfront": false,
                    "quotationInitialPriceOffer": null,
                    "autobidMinimumPrice": null,
                    "autobidPriceDecrement": null,
                    "isAutobidEnabled": false,
                    "state": "PUBLISHED",
                    "publishApprovalStatus": 4,
                    "publishedOngoingApprovalStatus": 1,
                    "isActivatedByUser": true,
                    "createdDate": "2021-05-04T05:23:07.820006Z",
                    "createdBy": "SYSTEM",
                    "modifiedDate": "2021-05-19T05:53:46.831392Z",
                    "modifiedBy": "01F4GV0RRVFG2G9GMKFBV6F5ZY"
                }
            },
            {
                "cursor": "01F4TX9VEWS1Y8GKAQ7F01FQK1",
                "node": {
                    "id": "01F4TX9VEWS1Y8GKAQ7F01FQK1",
                    "name": "name 3",
                    "brand": "brand 3",
                    "description": "desc 3",
                    "sku": null,
                    "manufacturerSku": null,
                    "price": 20000.0,
                    "imageUrl": [
                        "https://image1.jpg",
                        "https://image2.jpg",
                        "https://image3.jpg"
                    ],
                    "tags": [],
                    "isDisplayPriceOnShopfront": false,
                    "isDisplayProductOnShopfront": false,
                    "quotationInitialPriceOffer": null,
                    "autobidMinimumPrice": null,
                    "autobidPriceDecrement": null,
                    "isAutobidEnabled": false,
                    "state": "PUBLISHED",
                    "publishApprovalStatus": 4,
                    "publishedOngoingApprovalStatus": null,
                    "isActivatedByUser": true,
                    "createdDate": "2021-05-04T05:23:37.564045Z",
                    "createdBy": "SYSTEM",
                    "modifiedDate": null,
                    "modifiedBy": null
                }
            },
            {
                "cursor": "01F4Y34BCH71WGPVCA7YXMQK7D",
                "node": {
                    "id": "01F4Y34BCH71WGPVCA7YXMQK7D",
                    "name": "test product 2",
                    "brand": "test brand 1",
                    "description": "test desc 1",
                    "sku": "sku 1",
                    "manufacturerSku": "man sku 1",
                    "price": 2.0E9,
                    "imageUrl": [
                        "https://aws3/location/producImage/example/ProductImage/img1.jpg",
                        "https://aws3/location/producImage/example/ProductImage/img2.jpg",
                        "https://aws3/location/producImage/example/ProductImage/img3.jpg"
                    ],
                    "tags": [
                        {
                            "id": "01F4GTRENCD1F6JSYFS1S4ENT2",
                            "tag": "makanan"
                        },
                        {
                            "id": "01F4GTRNRW79DF4PG27K00XST7",
                            "tag": "minuman"
                        }
                    ],
                    "isDisplayPriceOnShopfront": false,
                    "isDisplayProductOnShopfront": false,
                    "quotationInitialPriceOffer": 20000.0,
                    "autobidMinimumPrice": 20000.0,
                    "autobidPriceDecrement": 20000.0,
                    "isAutobidEnabled": false,
                    "state": "PUBLISHED",
                    "publishApprovalStatus": 4,
                    "publishedOngoingApprovalStatus": null,
                    "isActivatedByUser": true,
                    "createdDate": "2021-05-05T11:03:12.016631Z",
                    "createdBy": "01F4GV1Y580CFS3E031WWB0Q10",
                    "modifiedDate": "2021-05-11T05:59:45.288201Z",
                    "modifiedBy": "01F4GV0RRVFG2G9GMKFBV6F5ZY"
                }
            }
        ]
    }
  }

  currentRoute:string=''


  OnRfqSearch:boolean=false
  dataRfqBoard:any=[]
  ValPagingRFQ:any = 40

  constructor(
    private apollo: Apollo,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private authService: AuthService,
    private productService: ProductService,
    private othersService: OthersService,
    private meta: Meta,
    private titleService: Title,
  ) { 
    this.currentRoute = "";
    var getketword  = this.route.snapshot.paramMap.get('keyword');
    var titleShare = ''
    if(getketword){
      titleShare = decodeURIComponent(getketword)
    }
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
            // Show progress spinner or progress bar
            // console.log('Route change detected');
            // alert('Route change detected')
        }

        if (event instanceof NavigationEnd) {
            // Hide progress spinner or progress bar
            this.currentRoute = event.url;          
            // console.log(event);
            // alert(event)

            // var getketword  = this.route.snapshot.paramMap.get('keyword');
            console.log('EditProductId',getketword);
            if(getketword){
              this.keyword= getketword
              this.prevkeyword = decodeURIComponent(getketword)
            }
            this.getsearch()

        }

        if (event instanceof NavigationError) {
             // Hide progress spinner or progress bar

            // Present error to user
            console.log(event.error);
            alert('error')
        }
    });

    //get rfq keyword
    var getketwordRfq  = this.route.snapshot.paramMap.get('keywordRfq');
    var titleShare = ''
    if(getketwordRfq){
      titleShare = decodeURIComponent(getketwordRfq)
      this.OnRfqSearch = true
    }
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
            // Show progress spinner or progress bar
            // console.log('Route change detected');
            // alert('Route change detected')
        }

        if (event instanceof NavigationEnd) {
            // Hide progress spinner or progress bar
            this.currentRoute = event.url;          
            // console.log(event);
            // alert(event)

            // var getketword  = this.route.snapshot.paramMap.get('keyword');
            console.log('EditProductId',getketwordRfq);
            if(getketwordRfq){
              this.keyword= getketwordRfq
              this.prevkeyword = decodeURIComponent(getketwordRfq)
            }
            this.getsearchRFQ()

        }

        if (event instanceof NavigationError) {
             // Hide progress spinner or progress bar

            // Present error to user
            console.log(event.error);
            alert('error')
        }
    });


    	//Set Open Graph
      this.meta.updateTag({property: 'og:title', content: 'Temukan '+titleShare+' di CepetDapet.com'});
      // this.meta.updateTag({property: 'og:image', content: 'image'});
      this.meta.updateTag({property: 'og:url', content: window.location.href});
      // this.meta.updateTag({property: 'og:description', content: 'desc'});

      //Set Twitter Share
      this.meta.updateTag({name: 'twitter:title', content: 'Temukan '+titleShare+' di CepetDapet.com'});
      // this.meta.updateTag({name: 'twitter:image', content: 'image twitter'});
      this.meta.updateTag({name: 'twitter:url', content: window.location.href});
      //this.meta.updateTag({name: 'twitter:description', content: 'desc twitter'});

  }

  ngOnInit(): void {

    this.innerWidth = window.innerWidth;
    console.log('this.innerWidth',this.innerWidth);
    if(this.innerWidth < 992){
      this.isMobile=true
      this.ValPaging = 4
    } else {
      this.isMobile=false
      this.ValPaging = 20
    }


    // console.log('isLoggedIn',this.authService.getJwtToken())
    // if(this.authService.getJwtToken()){
    //   this.isLogin = true
    // }
    
    if (localStorage.getItem("COMPANYSELECTID") !== null) {
      this.isLogin = true
    } 


    var getketword  = this.route.snapshot.paramMap.get('keyword');
    console.log('EditProductId',getketword);
    if(getketword){
      this.keyword= getketword
      this.prevkeyword = decodeURIComponent(getketword)
      this.getsearch()
    }
    
    var getketwordRfq  = this.route.snapshot.paramMap.get('keywordRfq');
    console.log('EditProductId',getketwordRfq);
    if(getketwordRfq){
      this.keyword= getketwordRfq
      this.prevkeyword = decodeURIComponent(getketwordRfq)
      this.getsearchRFQ()
    }
    
    if(getketwordRfq){
      this.titleService.setTitle('Search RFQ '+this.keyword+' | CepetDapet')
    } else {
      this.titleService.setTitle('Search Product '+this.keyword+' | CepetDapet')
    }

    // var getketword  = this.route.snapshot.paramMap.get('keyword');
    // console.log('EditProductId',getketword);
    // if(getketword){
    //   this.keyword= getketword
    //   this.prevkeyword = decodeURIComponent(getketword)
    // }
    // this.getsearch()
    // this.router.navigate(['/search/'+keyword])
    // .then(() => {
    //   window.location.reload();
    // });
    

    
    // this.ValPaging = this.first
    // var getListPerPage = localStorage.getItem("LISTPERPAGEPROC")
    // console.log('getListPerPage',getListPerPage);
    // if(getListPerPage){
    //   console.log('ada');
    //   this.first=parseInt(getListPerPage)
    //   this.ValPaging =getListPerPage
    // }else {
    //   console.log('ga aada');
    // }
    //this.productsList()
    //this.sampledata()
    // this.getsearch()
  }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
      this.innerWidth = window.innerWidth;
      //console.log('innerWidth',this.innerWidth);
  
      if(this.innerWidth < 992){
        this.isMobile=true
      } else {
        this.isMobile=false
      }
    }

  productPerPage(val: any){
    this.currentpage = 1
    this.offset = 0
    this.ValPaging=val
    
    this.getsearch()

    // console.log('productPerPage',val);
    // this.first = val

    // localStorage.setItem('LISTPERPAGEPROC', val);

    // this.AlldataProducts = []
    // this.productsList()
  }

  // loadMore(){
  //   let first = this.first
  //   let after = this.endCursor
  //   this.loadingNext = true
  //   this.apollo
  //   .mutate({
  //     mutation: querycompanyProducts,
  //     variables: {first: first, after: after}
  //   })
  //   .subscribe(
  //     (res:any) => {
  //       console.log("succes, get loadMore data:", res);

  //       this.loadingNext = false
  //       if(res.data){
  //         console.log("ada data");
  //         this.PageLoading=false
  //         this.dataProducts = res.data.companyProducts.edges
  //         var arrProd = res.data.companyProducts.edges

  //         var arrProd = res.data.companyProducts.edges
  //         arrProd.forEach((value: any, key: any) => {
  //           this.AlldataProducts.push(value)
  //         })

  //         this.endCursor = res.data.companyProducts.pageInfo.endCursor
  //         this.hasNextPage = res.data.companyProducts.pageInfo.hasNextPage
  //         console.log('endCursor',this.endCursor);
  //         console.log('hasNextPage',this.hasNextPage);

  //         localStorage.removeItem('PRODUCTLIST');
  //         localStorage.setItem('PRODUCTLIST', JSON.stringify(this.AlldataProducts));

  //       }
  //     },
  //     error => {
  //       alert(error)
  //       console.log("there was an error sending the query", error);     
  //       this.PageLoading=false  
  //       this.loadingNext = false     
  //     }
  //   );    
  // }

  loadNext(){
    this.currentpage = this.currentpage+1

    this.offset = (this.currentpage-1)*this.ValPaging
    console.log('loadNext offset ',this.offset)
    console.log('loadNext currentpage ',this.currentpage)

    this.getsearch()
  }

  loadPrev(){
    this.currentpage = this.currentpage-1

    console.log('currentpage ',this.currentpage)
    this.offset = (this.currentpage-1)*this.ValPaging
    console.log('loadPrev offset ',this.offset)
    console.log('loadPrev currentpage ',this.currentpage)

    this.getsearch()

  }


  productsList(){
    this.dataProducts = null
    this.PageLoading=true
    let first = this.first
    let after =""
    this.apollo
    .mutate({
      mutation: querycompanyProducts,
      variables: {first: first, after: after}
    })
    .subscribe(
      (res:any) => {
        console.log("succes, get data:", res);

        
        if(res.data){
          console.log("ada data");
          this.PageLoading=false
          this.dataProducts = res.data.companyProducts.edges
          
          var arrProd = res.data.companyProducts.edges
          arrProd.forEach((value: any, key: any) => {
            this.AlldataProducts.push(value)
          })

          this.endCursor = res.data.companyProducts.pageInfo.endCursor
          this.hasNextPage = res.data.companyProducts.pageInfo.hasNextPage
          console.log('endCursor',this.endCursor);
          console.log('hasNextPage',this.hasNextPage);

          localStorage.removeItem('PRODUCTLIST');
          localStorage.setItem('PRODUCTLIST', JSON.stringify(this.AlldataProducts));

          this.arrPrevCursor.push(after)
          console.log('arrPrevCursor',this.arrPrevCursor);

        }
      },
      error => {
        alert(error)
        console.log("there was an error sending the query", error);     
        this.PageLoading=false       
      }
    );
  }

  getsearch(){
    this.PageLoading=true
    this.pagingloading = true
    var keyword = decodeURI(this.keyword)
    this.productService.getglobalSearchList(keyword,this.ValPaging,this.offset)
    .subscribe((res:any) => {
      console.log(res)
      this.PageLoading=false
      this.pagingloading = false
      if(res.data?.userGlobalSearchProduct){
          //console.log("ada data");


          this.AlldataProducts=[]
          var arrProd = res.data.userGlobalSearchProduct.productGlobalDetail
          arrProd.forEach((value: any, key: any) => {
            this.AlldataProducts.push(value)
          })

          this.totaldata = res.data.userGlobalSearchProduct.totalData
          
          // this.offset = (this.currentpage-1)*this.ValPaging
          
          this.loadPaging()
     
      }
      else if(res.errors){
   
        const modalRef = this.modalService.open(ModalComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          backdrop : 'static',
          keyboard : false,
          size: 'sm'
        })

        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = msg

        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Opps Something Wrong!' // 'Success!' 
        modalRef.componentInstance.infoSuccess = null
        modalRef.componentInstance.infoFailed = errortext
        modalRef.componentInstance.closeTxt = 'Close'

      }
      else {
        const modalRef = this.modalService.open(ModalComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          backdrop : 'static',
          keyboard : false,
          size: 'sm'
        })
        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Opps Something Wrong!' // 'Success!' 
        modalRef.componentInstance.infoSuccess = null
        modalRef.componentInstance.infoFailed = 'Please try again later'
        modalRef.componentInstance.closeTxt = 'Close'

        modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
          if (res){
            this.router.navigate(['/']);
          } 
        })
      }
      
    });

  }
  
  loadPaging(){
    var gettotal = Math.ceil(this.totaldata/this.ValPaging)
    this.totalpage = gettotal
    console.log('gettotal ',gettotal)
    if(this.currentpage==gettotal){
      this.hasNextPage=false
    } else {
      this.hasNextPage=true
    }

    if(this.currentpage==1){
      this.hasPrevPage=false
    } else {
      this.hasPrevPage=true
    }

    if(gettotal==0){
      this.hasNextPage=false
    }
    console.log('if',(this.totalpage==this.currentpage))
    if(this.totalpage==this.currentpage){
      this.hasNextPage=false
    }

    //if last page
    if(this.hasNextPage==false && gettotal!==0 && this.ValPaging<this.totaldata){
      if(!this.isLogin){
        this.LastResult = true
      }   
    } else {
      if(this.totalpage==this.currentpage){
        if(!this.isLogin){
          this.LastResult = true
        }  
      } else {
        this.LastResult = false
      }
      // this.LastResult = false
    }
  }


  getsearchRFQ(){
    this.PageLoading=true
    this.pagingloading = true
    var keyword = decodeURI(this.keyword)
    this.othersService.getanyoneSearchRfq(getIdRfq.filter(keyword),this.ValPagingRFQ,'')
    .subscribe((res:any) => {

      console.log(res)
      this.PageLoading=false
      this.pagingloading = false


      if(res.data?.anyoneSearchRfq){
        var publicRFQ = res.data?.anyoneSearchRfq.edges

        publicRFQ.forEach((value: any, key: any) => {


          var categoryComma ='';
          if(value.node.rfqVendorCategory){
            categoryComma = value.node.rfqVendorCategory.join(", ")
          }
          

          var dataBoard ={
            city:value.node.rfqDeliveryCity,
            country:value.node.rfqDeliveryCountry,
            submitQuotationLastDate:value.node.submitQuotationLastDate,
            totalVendorParticipation:value.node.totalParticipation,
            procurementName:value.node.procurementName,
           // rfqState:value.node.rfqState,
            rfqState:null,
            isAlreadyParticipating:value.node.isAlreadyParticipating,
            rfqVendorCategory:categoryComma,
            rfqDisplayId:value.node.rfqDisplayId,
            description:value.node.description,
            rfqItem:value.node.rfqItem,
            haveMoreRfqItem:value.node.haveMoreRfqItem,
            sponsored:false,
            //url:this.redirectUrl(value.node.rfqId,value.node.rfqParticipationId,value.node.quotationId)
            url:'/regis'
          }
          console.log('dataBoard : ',dataBoard)
          this.dataRfqBoard.push(dataBoard)


        })
        if(publicRFQ.length>40){
          if(!this.isLogin){
            this.LastResult = true
          }  
          
        }
        
      }

      else if(res.errors){
   
        const modalRef = this.modalService.open(ModalComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          backdrop : 'static',
          keyboard : false,
          size: 'sm'
        })

        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = msg

        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Opps Something Wrong!' // 'Success!' 
        modalRef.componentInstance.infoSuccess = null
        modalRef.componentInstance.infoFailed = errortext
        modalRef.componentInstance.closeTxt = 'Close'

      }
      else {
        const modalRef = this.modalService.open(ModalComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          backdrop : 'static',
          keyboard : false,
          size: 'sm'
        })
        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Opps Something Wrong!' // 'Success!' 
        modalRef.componentInstance.infoSuccess = null
        modalRef.componentInstance.infoFailed = 'Please try again later'
        modalRef.componentInstance.closeTxt = 'Close'

        modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
          if (res){
            this.router.navigate(['/']);
          } 
        })
      }
      
    });

  }


  sampledata(){
              this.productsList = this.dummydata

          var arrProd =  this.dummydata.companyProducts.edges
          arrProd.forEach((value: any, key: any) => {
            this.AlldataProducts.push(value)
          })
  }
}
